<template>
  <li class="nav-item">
    <a @click="view" :id="id" :class="classes" :href="href" role="tab" data-toggle="tab">
      <span>{{ title }}<small v-if="typeof count !== 'undefined'"> ({{count}})</small></span></a>
  </li>
</template>
<script>
export default {
  name: 'nav-item-main-panel',
  props: ['href', 'view', 'id', 'classes', 'title', 'count']
}
</script>
