<template>
  <div>
    <div class="col-sm-12 pt-2 pb-2">
      <div class="row" v-if="additionalServices.length > 0">
        <div class="col-md-4">
          <button @click="deleteAdditionalServices" class="button">{{ translations.Back }}</button>
        </div>
      </div>
    </div>
    <div class="col-12 mt-2 d-flex flex-column-reverse">
      <template v-for="(additionalService, index) in additionalServices">
        <div class="row pb-2 pt-2 border-bottom" :key="index">
          <div class="col-sm-12 mt-2">
            <strong>{{index+1}}. {{ additionalService._servDesc }}</strong>
          </div>
          <div class="col-sm-12 mt-2">
            {{ translations.Service_time }}
          </div>
          <div class="col-sm-12 mt-2">
            <div class="radios-as-buttons mt-2">
              <template v-if="additionalServicesTime">
                <div v-for="(time,key) in additionalServicesTime" :key="key">
                  <input @change="refreshAdditionalServices(additionalService._servId,'time')" :name="'time'+additionalService._servId"
                         :disabled="isDisabledAdditional(index)"
                         :id="time._time + '_adservice_' + additionalService._servId + '_checkbox'"
                         type="radio" :value="time._time" v-model="selectedAdditionalServices[additionalService._servId + '_time']"/>
                  <label :for="time._time + '_adservice_' + additionalService._servId + '_checkbox'">{{ time._name }}</label>
                </div>
              </template>
            </div>
          </div>
          <div class="col-sm-12 mt-2">
            {{ translations.Free_time }}
          </div>
          <div class="col-sm-12 mt-2" v-if="additionalServicesWantTime[index]">
            <div class="radios-as-buttons mt-2">
              <template v-if="additionalServicesWantTime[index]['row']._time">
                <div >
                  <input @change="refreshAdditionalServices(additionalService._servId,'time_want')"  :name="'time_want'+additionalService._servId" :disabled="isDisabledAdditional(index)"
                         :id="additionalServicesWantTime[index]['row']._time + '_adservice_' + additionalService._servId + '_time_want_checkbox'"
                         type="radio" :value="additionalServicesWantTime[index]['row']._time" v-model="selectedAdditionalServices[additionalService._servId + '_time_want']"/>
                  <label :for="additionalServicesWantTime[index]['row']._time + '_adservice_' + additionalService._servId + '_time_want_checkbox'">{{additionalServicesWantTime[index]['row'].time | additionalTimeFormat}}</label>
                </div>
              </template>
              <template  v-else>
                <div v-for="(time,key) in additionalServicesWantTime[index]['row']" :key="key">
                  <input @change="refreshAdditionalServices(additionalService._servId,'time_want')"  :name="'time_want'+additionalService._servId" :disabled="isDisabledAdditional(index)"
                         :id="time._time + '_adservice_' + additionalService._servId + '_time_want_checkbox'"
                         type="radio" :value="time._time" v-model="selectedAdditionalServices[additionalService._servId + '_time_want']"/>
                  <label :for="time._time + '_adservice_' + additionalService._servId + '_time_want_checkbox'">{{time.time | additionalTimeFormat}}</label>
                </div>
              </template>
            </div>
          </div>
          <br />
        </div>
      </template>
    </div>
    <div class="col-sm-12 pt-2 pb-2">
      <div class="row" v-if="additionalServices.length > 0">
        <div class="col-md-4">
          <button @click="addAdditionalServices" class="button">{{ translations.Accept }}</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import websocket from '../../../../../shared/websocket'
import queueCallbacks from '../../../../../shared/callbacks/queue.callbacks'
export default {
  name: 'additional-registration',
  computed: {
    translations () {
      return this.$store.getters.translations
    },
    additionalServices () {
      return this.$store.getters.additionalServices
    },
    additionalServicesTime () {
      return this.$store.getters.additionalServicesTime
    },
    selectedAdditionalServices () {
      return this.$store.getters.selectedAdditionalServices
    },
    additionalServicesWantTime () {
      return this.$store.getters.additionalServicesWantTime
    }
  },
  filters: {
    additionalTimeFormat: function (value) {
      return (new Date('1970/01/01 ' + value)).toLocaleTimeString('ru', { hour: '2-digit', minute: '2-digit' })
    }
  },
  methods: {
    refreshAdditionalServices: function (servId, type) {
      switch (type) {
        case 'time':
          if (this.$store.getters.selectedAdditionalServices[servId + '_time']) {
            websocket.addTimeAdditionalServices(servId, queueCallbacks.refreshAdditionalServicesResponse)
          }
          break
        case 'time_want':
          if (this.$store.getters.selectedAdditionalServices[servId + '_time_want']) {
            websocket.addTimeWantAdditionalServices(servId, queueCallbacks.refreshAdditionalServicesResponse)
          }
          break
      }
    },
    addAdditionalServices: function () {
      websocket.addAdditionalServices()
    },
    deleteAdditionalServices: function () {
      websocket.deleteAdditionalServices()
    },
    isDisabledAdditional (index) {
      return index !== 0 && (typeof this.additionalServices[index - 1] === 'undefined' ||
        !(this.selectedAdditionalServices[this.additionalServices[index - 1]._servId + '_time'] &&
          this.selectedAdditionalServices[this.additionalServices[index - 1]._servId + '_time_want']))
      // return false;
    }
  }
}
</script>
