<template>
  <div>
    <b-modal id="print-modal">
      {{translations.Docstate}}{{data.checkData.docstate}}<br />
      {{translations.Equery_num}}{{data.checkData.equeryNum}}<br />
      {{translations.Equery_date}}{{checkDataDate}}<br />
      {{translations.Equery_time}}{{checkDataTime}}<br />
      <template v-slot:modal-footer>
        <b-button variant="primary" @click="closeModal">{{ translations.Ok }}</b-button>
        <b-button variant="primary" @click="print">{{ translations.Print }}</b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import websocket from '../../shared/websocket'
export default {
  name: 'print-modal',
  methods: {
    print: function () {
      // websocket.printCheck(this.data.original, this.data.checkData)
      websocket.forcePrint(this.data.checkData.equeryNum, this.data.checkData.equeryDateAndroid)
      console.log(this.data.original, this.data.checkData)
      this.closeModal()
    },
    closeModal: function () {
      this.$bvModal.hide('print-modal')
    }
  },
  computed: {
    translations () {
      return this.$store.getters.translations
    },
    data () {
      return this.$store.getters.printData
    },
    checkDataDate () {
      return (new Date(this.data.checkData.equeryDate)).toLocaleDateString('ru')
    },
    checkDataTime () {
      return (new Date(this.data.checkData.equeryDate + ' ' + this.data.checkData.equeryTime)).toLocaleTimeString('ru', { hour: '2-digit', minute: '2-digit' })
    }
  }
}
</script>
