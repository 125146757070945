<template>
  <div v-cloak class="datetime">
    <div class="time"><span><i v-if="isSocketOpened" class="online"></i><i v-if="!isSocketOpened" class="offline"></i>{{serverTime.time}}</span></div>
    <marquee @click="clearMarquee" class="blink-text" v-if="blinkText">{{blinkText}}</marquee>
    <div class="date">
      <span class="weekday">{{serverTime.day}}</span>
      <span>{{ serverDate }}</span>
    </div>
  </div>
</template>
<script>
export default {
  name: 'header-date-time',
  methods: {
    clearMarquee: function () {
      this.blinkText = false
    }
  },
  computed: {
    blinkText () {
      return this.$store.getters.blinkText
    },
    serverTime () {
      return this.$store.getters.serverTime
    },
    serverDate () {
      if (!this.$store.getters.serverTime.fulltime) {
        return ''
      }
      return ', ' + (new Date(this.$store.getters.serverTime.fulltime)).toLocaleDateString('ru')
    },
    isSocketOpened () {
      return this.$store.getters.readyState === 1
    }
  }
}
</script>
