<template>
  <div v-cloak class="operators">
    <div class="row d-flex align-items-center">
      <div class="col-6 col-md-7">
        {{operator.firstName?operator.firstName:firstname}}
      </div>
      <div class="col-6 col-md-5"><img src="../../../assets/img/logo.svg" class="w-100"/></div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'current-operator',
  computed: {
    operator () {
      return this.$store.getters.currentOperator
    }
  },
  data () {
    const firstname = localStorage.getItem('firstname') ? localStorage.getItem('firstname') : ''
    return { firstname: firstname }
  }
}
</script>
