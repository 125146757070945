<template>
  <custom-table
    :classes="'table tab-table table-responsive'"
    :headers="headers"
    :rows="rows"
    :columns="columns"
  >
    <template v-slot:actions="slotProps">
      <waiting-queue-buttons :data="slotProps.data"/>
    </template>
  </custom-table>
</template>
<script>
import Table from '../../../Base/Table'
import WaitingQueueButtons from './WaitingQueueButtons'
export default {
  name: 'waiting-queue',
  components: { 'custom-table': Table, 'waiting-queue-buttons': WaitingQueueButtons },
  props: ['buttonFirstClick'],
  data () {
    return { columns: ['fio', 'platname', 'equery_num', 'actions'] }
  },
  computed: {
    translations () {
      return this.$store.getters.translations
    },
    headers () {
      return [
        this.translations.FIO, this.translations.Package_service_record_queue,
        this.translations.Ticket_time, this.translations.Action
      ]
    },
    rows () {
      const rows = this.$store.getters.waitingQueueRows
      return rows.map(row => {
        return {
          fio: row._fio,
          platname: `<span class="">${row._platname}</span>`,
          equery_num: `${row._equery_num}${(row._time_start) ? ', ' + (new Date(row._time_start))
            .toLocaleDateString('ru') + ' ' + (new Date(row._time_start))
            .toLocaleTimeString('ru', { hour: '2-digit', minute: '2-digit' }) : ''}`,
          actions: { ...row, buttons: row.buttons, buttonFirstClick: this.buttonFirstClick }
        }
      })
    }
  }
}
</script>
