/* eslint no-extend-native: ["error", { "exceptions": ["String"] }] */
String.prototype.hexEncode = function () {
  var utf8string = this.utf8Encode()
  var hex = ''
  for (var i = 0; i < utf8string.length; i++) {
    hex += '' + utf8string.charCodeAt(i).toString(16)
  }
  return hex
}

String.prototype.hexDecode = function () {
  var hex = this.toString()// force conversion
  var str = ''
  for (var i = 0; i < hex.length; i += 2) {
    str += String.fromCharCode(parseInt(hex.substr(i, 2), 16))
  }
  return str
}

String.prototype.utf8Encode = function () {
  if (this === null || typeof this === 'undefined') {
    return ''
  }

  var string = (this + '')
  var utftext = ''
  var start
  var end
  var stringl = 0

  start = end = 0
  stringl = string.length
  for (var n = 0; n < stringl; n++) {
    var c1 = string.charCodeAt(n)
    var enc = null

    if (c1 < 128) {
      end++
    } else if (c1 > 127 && c1 < 2048) {
      enc = String.fromCharCode(
        (c1 >> 6) | 192, (c1 & 63) | 128
      )
    } else if ((c1 & 0xF800) !== 0xD800) {
      enc = String.fromCharCode(
        (c1 >> 12) | 224, ((c1 >> 6) & 63) | 128, (c1 & 63) | 128
      )
    } else {
      // surrogate pairs
      if ((c1 & 0xFC00) !== 0xD800) {
        throw new RangeError('Unmatched trail surrogate at ' + n)
      }
      var c2 = string.charCodeAt(++n)
      if ((c2 & 0xFC00) !== 0xDC00) {
        throw new RangeError('Unmatched lead surrogate at ' + (n - 1))
      }
      c1 = ((c1 & 0x3FF) << 10) + (c2 & 0x3FF) + 0x10000
      enc = String.fromCharCode(
        (c1 >> 18) | 240, ((c1 >> 12) & 63) | 128, ((c1 >> 6) & 63) | 128, (c1 & 63) | 128
      )
    }
    if (enc !== null) {
      if (end > start) {
        utftext += string.slice(start, end)
      }
      utftext += enc
      start = end = n + 1
    }
  }

  if (end > start) {
    utftext += string.slice(start, stringl)
  }

  return utftext
}

String.prototype.utf8Decode = function () {
  var string = ''
  var i = 0
  var c1 = 0
  var c2 = 0
  var c3 = 0

  while (i < this.length) {
    c1 = this.charCodeAt(i)
    if (c1 < 128) {
      string += String.fromCharCode(c1)
      i++
    } else if ((c1 > 191) && (c1 < 224)) {
      c2 = this.charCodeAt(i + 1)
      string += String.fromCharCode(((c1 & 31) << 6) | (c2 & 63))
      i += 2
    } else {
      c2 = this.charCodeAt(i + 1)
      c3 = this.charCodeAt(i + 2)
      string += String.fromCharCode(((c1 & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63))
      i += 3
    }
  }
  return string
}
