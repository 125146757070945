<template>
  <div v-cloak :class="(Array.isArray(operatorCurrentClient)) ? 'invisible mx-0 py-3' : 'mx-0 py-3'">
    <div class="ticket-descr px-3">
      <h3 ><b>{{ translations.Ticket }}</b> {{operatorCurrentClient._equery_num}} </h3>
      <div class="wrapertime">{{ operatorCurrentClient.start }} - {{operatorCurrentClient.end}}
        <template v-if="operatorCurrentClient.lost">
          <br/>
          {{translations.textLost}} {{operatorCurrentClient.lost}}
        </template>
      </div>
    </div>
    <div class="ticket-info px-3">
      <div class="my-3">
        <span class="ticket-fio"><span v-if="+operatorCurrentClient._eqtype===0">{{ translations.Current_queue }}</span>
          <span v-if="+operatorCurrentClient._eqtype===1">{{ translations.By_appointment }}</span></span>
      </div>
      <div class="my-3">
        <span class="ticket-fio">{{operatorCurrentClient._fio}}</span>
      </div>
      <div class="my-3">
        <span class="ticket-status">{{ operatorCurrentClient._docstate}}</span>
      </div>
      <div v-if="option === 'autominutes'" >
        <span class="ticket-status-label">{{ translations.Automatic }}:</span>
        <span class="ticket-status">{{ autoTimeCurrent.minute}}:{{ autoTimeCurrent.second}}</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ticket',
  computed: {
    time_start () {
      console.log(this.operatorCurrentClient._time_start)
      return 1
    },
    serverTime () {
      return this.$store.getters.serverTime
    },
    operatorCurrentClient () {
      const current = this.$store.getters.operatorCurrentClient
      current.last = null
      if (this.serverTime.fulltime && current._status === '2') {
        const fulltime = this.serverTime.fulltime
        const lost = (new Date(current._time_end) - new Date(fulltime)) / 1000
        if (lost >= 0) {
          current.lost = this.formatLostTime(lost)
        }
      }
      current.start = this.formatTime(current._time_start)
      current.end = this.formatTime(current._time_end)
      return current
    },
    translations () {
      return this.$store.getters.translations
    },
    option () {
      return this.$store.getters.option
    },
    autoTimeCurrent () {
      return this.$store.getters.autoTimeCurrent
    }
  },
  methods: {
    formatTime: function (time) {
      return (new Date(time)).toLocaleTimeString('ru', { hour: '2-digit', minute: '2-digit' })
    },
    formatLostTime: function (lost) {
      var days = Math.floor((lost % (60 * 60 * 24 * 30)) / (60 * 60 * 24))
      var hours = Math.floor((lost % (60 * 60 * 24)) / (60 * 60))
      var minutes = Math.floor((lost % (60 * 60)) / (60))
      // var seconds = Math.floor((lost % 60))
      let time = ''
      if (days) {
        time += days + ' ' + this.translations.shortDays + ' '
      }
      if (hours) {
        time += hours + ' ' + this.translations.shortHours + ' '
      }
      if (minutes) {
        time += minutes + ' ' + this.translations.shortMinutes + ' '
      } else {
        time += '--' + ' ' + this.translations.shortMinutes + ' '
      }
      // time += seconds + this.translations.shortSeconds + ' '
      return time
    }
  }
}
</script>
