<template>
  <div>
    <div class="row">
      <div class="col-8 d-flex align-items-center">
        {{config.servname}}
      </div>
      <div class="col-4">
        <button type="button" class="btn btn-primary" @click="logoutConfig"><i class="logout"></i></button>
      </div>
    </div>
    <br />
    <label for="username">{{ translations.labelOperator }}</label>
    <select id="username" class="form-control" v-model="username">
      <option v-for="(operator,key) in operators" :key="key" :value="operator._login">{{operator._login}}</option>
    </select>
    <br />
    <!--<label for="password">{{ translations.labelPassword }}</label>
    <input v-model="password" id="password" type="password" class="form-control">
    <br />-->
    <button type="button" class="button" @click="login">{{ translations.labelLogin }}</button>
  </div>
</template>
<script>
import websocket from '../../shared/websocket'
export default {
  name: 'login-operator',
  data () {
    return {
      password: '',
      username: ''
    }
  },
  methods: {
    logoutConfig () {
      localStorage.setItem('config_logged_out', true)
      localStorage.removeItem('config')
      this.$store.dispatch('updateConfigLoggedOut', true)
    },
    login () {
      websocket.authenticateOperator(this.username, this.password)
    }
  },
  computed: {
    config () {
      return this.$store.getters.config
    },
    operators () {
      return this.$store.getters.operators
    },
    translations () {
      return {
        labelOperator: this.$store.getters.translations.Operator,
        labelPassword: this.$store.getters.translations.Password,
        labelLogin: this.$store.getters.translations.Login
      }
    }
  }
}
</script>
