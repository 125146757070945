<template>
  <div class="messages" v-scroll-bottom="message[activeOper]">
    <ul>
      <template v-for="(messageRow,key) in message[activeOper]">
        <li v-if="messageRow.type === 'from'" class="sent" :key="key">
          <img src="../../../../assets/img/user-profile.png" :alt="activeOper" />
          <p>{{ messageRow.message }}<br/>
            <span class="time_date" v-if="messageRow.time">{{messageRow.time | messageTimeFilter}}, {{messageRow.date | messageDateFilter}}</span></p>
        </li>
        <li v-else class="replies"  :key="key">
          <p>{{messageRow.message}}<br/>
            <span class="time_date" v-if="messageRow.time">{{messageRow.time | messageTimeFilter}}, {{messageRow.date | messageDateFilter}}</span></p>
        </li>
      </template>
    </ul>
  </div>
</template>
<script>
import Vue from 'vue'
export default {
  name: 'chat-messages',
  computed: {
    activeOper () {
      return this.$store.getters.chat.activeOper
    },
    message () {
      return this.$store.getters.chat.message
    }
  },
  filters: {
    messageDateFilter: function (value) {
      return (new Date(value)).toLocaleDateString('ru')
    },
    messageTimeFilter: function (value) {
      return (new Date('1970/01/01 ' + value)).toLocaleTimeString('ru', { hour: '2-digit', minute: '2-digit' })
    }
  },
  directives: {
    'scroll-bottom' (el) {
      Vue.nextTick()
        .then(function () {
          el.scrollTop = el.scrollHeight - el.clientHeight
        })
    }
  }
}
</script>
