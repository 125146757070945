<template>
  <div class="message-input">
    <div class="wrap">
      <label for="input-message"/>
      <input type="text" id="input-message" class="write_msg" @keyup.enter="sendActiveOperMessage"  :placeholder="translations.type_message" />
      <i class="fa fa-sign-out attachment"  @click="sendActiveOperMessageInvite" aria-hidden="true" ></i>
      <i class="fa fa-circle  attachment" aria-hidden="true"  @click="sendActiveOperMessageInvite" ></i>
      <button class="submit" @click="sendActiveOperMessage"><i class="fa fa-paper-plane" aria-hidden="true"></i></button>
    </div>
  </div>
</template>
<script>
import websocket from '../../../../shared/websocket'
import utils from '../../../../shared/libs/utils'
import $ from 'jquery/dist/jquery.min'
export default {
  name: 'chat-message-action',
  computed: {
    activeOper () {
      return this.$store.getters.chat.activeOper
    },
    message () {
      return this.$store.getters.chat.message
    },
    translations () {
      return this.$store.getters.translations
    }
  },
  methods: {
    sendActiveOperMessage: async function () {
      await this.sendMessage($('.write_msg').val())
    },
    sendActiveOperMessageInvite: async function () {
      await this.sendMessage(this.translations.MessageInvite)
    },
    sendMessage: async function (message) {
      const login = this.activeOper
      $('.write_msg').val('')
      const newMessage = { type: 'to', message: message }
      if (!utils.isdef(this.message[login])) {
        await this.$store.dispatch('updateChatMessageByLogin', { login, val: [] })
      }
      await this.$store.dispatch('pushChatMessageByLogin', { login, val: newMessage })
      websocket.sendmessageOper(login, message)
    // queue_app.$forceUpdate(); //проблемы с реактивностью
    }
  }
}
</script>
