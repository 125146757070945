<template>
  <div v-cloak class="col-12">
    <div class="row operator-actions">
      <div v-for="(button,key) in buttons" :key="key" class="col" >
        <button class="button" :disabled="button.disabled" @click="buttonClick" :id="button.id"
                :data-toggle="button.toggle"  :data-target="button.target" :data-num="currentClient._equery_num"
                :data-couponcode="button.couponCode" :data-clientid="currentClient._clientid" v-html="button.text"></button>
      </div>
    </div>
  </div>
</template>
<script>
import websocket from '../../../shared/websocket'
export default {
  name: 'operator-actions',
  computed: {
    buttons () {
      return this.$store.getters.buttons
    },
    currentClient () {
      return this.$store.getters.operatorCurrentClient
    }
  },
  methods: {
    buttonClick: async function (event) {
      const id = event.currentTarget.id
      switch (id) {
        case 'move':
          var clientId = parseInt(event.currentTarget.getAttribute('data-clientid'))
          await websocket.getDeferOper(clientId, async (response) => {
            await this.$store.dispatch('updateMovedModalDataByField', { key: 'operators', val: response.row })
          })
          await this.$store.dispatch('updateMovedModalDataByField', { key: 'clientId', val: clientId })
          break
        case 'next':
          websocket.nextClient()
          break
        case 'more': // повторный вызов
          websocket.moreClient()
          break
        case 'start':
          await websocket.startClient(event.currentTarget.getAttribute('data-clientid'))
          break
        case 'complete':
          await websocket.completeClient(event.currentTarget.getAttribute('data-clientid'))
          break
        case 'delete':
          websocket.deleteClient()
          break
        case 'hold':
          websocket.holdClient()
          break
        case 'pause':
          await websocket.pauseOperator()
          break
        case 'unpause':
          await websocket.unpauseOperator()
          break
        case 'send_message':
          await this.$store.dispatch('updateMessageDataField', { key: 'message', val: '' })
          await this.$store.dispatch('updateMessageDataField', { key: 'check', val: '' })
          await this.$store.dispatch('updateMessageDataField', { key: 'couponCode', val: event.currentTarget.dataset.couponcode })
          await this.$store.dispatch('updateMessageDataField', { key: 'to', val: 'viber' })
          if (event.currentTarget.dataset.href) {
            await this.$store.dispatch('updateMessageDataField', { key: 'href', val: event.currentTarget.dataset.href })
          }
          break
      }
      websocket.getCurrentClient()
    }
  }
}
</script>
