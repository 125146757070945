import BigInt from 'big-integer'

export default {
  fromUIDToEq: function (valIn) {
    valIn = BigInt(valIn)
    valIn = valIn.value
    if (!this.isLongTCode(valIn)) {
      let val = valIn
      val = this.shufler(val)
      const uDat = val / 1000000
      val = val % 1000000
      const orgId = val / 1000
      val = val % 1000
      const num = val
      return { uDat: parseInt(uDat), orgId: parseInt(orgId), num: parseInt(num) }
    } else {
      let val = valIn
      val = this.shufler(val)
      const uDat = val / 1000000
      val = val % 1000000
      const orgId = val / 1000
      val = val % 1000
      const num = val
      return { uDat: parseInt(uDat), orgId: parseInt(orgId), num: parseInt(num) }
    }
  },
  toUIDFromEq: function (orgId, date, num) {
    if (orgId < 1000) {
      const uDat = this.getDay(date)
      const res = parseInt(orgId) * 1000 + parseInt(uDat) * 1000000 + parseInt(num)
      return (this.shufler(res))
    } else {
      const uDat = BigInt(this.getDay(date))
      let res = BigInt(orgId) * 10000 + uDat * 100000000 + BigInt(num)
      res = BigInt(this.shufler2(res))
      this.setBitN64(res, 37)
      // добавляем 37 бит
      return res + BigInt(Math.pow(2, 37))
    }
  },

  getDay: function (date) {
    date = date.substr(0, 10).split('-')
    const dateUTC = Date.UTC(parseInt(date[0]), parseInt(date[1], 10) - 1, parseInt(date[2]))
    const timestamp = new Date().setFullYear(new Date().getFullYear(), 0, 0)
    const yearFirstDay = Math.floor(timestamp / 86400000)
    const today = Math.ceil((dateUTC) / 86400000)
    return today - yearFirstDay
  },

  shufler2: function (valIn) {
    // только 29 бит - остальное нули 0-28)
    const shfl = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17]
    const shfr = [22, 18, 30, 19, 24, 27, 29, 32, 26, 34, 21, 28, 20, 31, 35, 23, 25, 33]

    let res = 0

    valIn = BigInt(valIn)

    for (let i = 0; i < 18; i++) {
      const lb = this.getBitN64(valIn, shfl[i])
      const rb = this.getBitN64(valIn, shfr[i])

      let mask = BigInt(0x00)

      if (lb) {
        mask = this.setBitN64(mask, shfr[i])
      }
      if (rb) {
        mask = this.setBitN64(mask, shfl[i])
      }
      res |= mask

      shfl[i] = 0
      shfr[i] = 0
    }

    return res
  },

  shufler: function (val) {
    // только 29 бит - остальное нули 0-28)
    const shfl = [1, 3, 2, 4, 0, 10, 11, 8, 9, 5, 7, 6, 12, 13, 14]
    const shfr = [25, 17, 18, 27, 15, 20, 26, 22, 21, 23, 24, 19, 28, 16, 14]

    let res = 0

    for (let i = 0; i < 15; i++) {
      const lb = this.getBitN64(val, shfl[i])
      const rb = this.getBitN64(val, shfr[i])

      let mask = 0x00

      if (lb) {
        mask = this.setBitN(mask, shfr[i])
      }
      if (rb) {
        mask = this.setBitN(mask, shfl[i])
      }
      res |= mask
      // console.log("iter " + i + " l " + shfl[i] + " "  + lb + " r " + shfr[i] + " "  + rb + " mask " + mask + " res " + res)

      shfl[i] = 0
      shfr[i] = 0
    }

    return res
  },

  setBitN64: function (val, nPOs) {
    val = BigInt(val)
    val |= 1 << BigInt(nPOs)
    return val
  },

  getBitN64: function (val, nPOs) {
    const mask = BigInt(1) << BigInt(nPOs)
    return mask & BigInt(val)
  },

  setBitN: function (val, nPos) {
    val |= 1 << nPos
    return val
  },

  isLongTCode: function (val) {
    return this.getBitN64(val, 37)
  }
}
