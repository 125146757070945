<template>
  <div id="send-message-modal" class="modal fade" role="dialog">
    <div class="modal-dialog">
      <!-- Modal content-->
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal">&times;</button>
        </div>
        <div class="modal-body">
          <div class="form-group">
            <label for="message">{{ translations.Message }}</label>
            <textarea class="form-control" v-model="messageData.message" id="message" cols="30" rows="10"></textarea>
          </div>
          <div class="form-group" v-if="messageData.href">
            <input type="checkbox" v-model="messageData.check" id="add-href"  class="checkbox only-checkbox"/>
            <label for="add-href"> {{ translations.addHref }}</label>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" data-dismiss="modal" @click="sendMessage">{{ translations.Send }}</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import websocket from '../../shared/websocket'
import messageCallbacks from '../../shared/callbacks/message.callbacks.js'
export default {
  name: 'send-message-modal',
  methods: {
    sendMessage: function () {
      let href = ''
      if (this.messageData.check) {
        href = this.messageData.href
      }
      const data = {
        message: encodeURI(this.messageData.message),
        href: (href) ? 'https:// ' + href : '',
        couponCode: this.messageData.couponCode
      }
      const request = {
        type: 'message',
        to: this.messageData.to,
        data: data
      }
      if (this.messageData.message) {
        websocket.sendRequestJson('message_to_bot', '', request, messageCallbacks.sendMessageBotResponse)
      }
    }
  },
  computed: {
    translations () {
      return this.$store.getters.translations
    },
    messageData () {
      return this.$store.getters.sendMessageData
    }
  }
}
</script>
