<template>
  <div id="registration-app" class="d-flex flex-wrap align-content-center justify-content-center w-100 h-100">
    <div v-cloak class="col-12 col-md-6" v-if="validated && ! succeeded">
      <div class="row">
        <div class="col-6 mt-2">
          <div v-if="registrationParams.length === 1">
            {{translations.Your_name + registrationParams[0].value}}
          </div>
          <template v-if="false">
          <div  v-for="(param,key) in registrationParams" :key="key">
            <label :for="'param_' + param.name">{{param.label}}</label>
            <input :disabled="param.disabled" :id="'param_' + param.name" :type="param.type" v-model="param.value" class="form-control">
          </div>
          </template>
        </div>
        <div class="col-sm-12 mt-2">
          <select v-if="services.length > 1" v-model="selectedService" @change="getFreeTime" class="form-control" size="2" >
            <option v-for="(service,key) in services" :key="key" :value="service._docstateid">{{service._docname}}</option>
          </select>
          <div v-if="services.length === 1">
            {{translations.Service_name + services[0]._docname}}
          </div>
        </div>
        <div class="col-sm-12 mt-2">
          <input type="date" v-model="queueDate" @change="getFreeTime" class="form-control w-50">
          <div class="radios-as-buttons mt-2">
            <div v-for="(freeTime,key) in freeTimes" :key="key">
              <input :id="formatTime(freeTime) + '_checkbox'" type="radio" :value="freeTime._time_start" v-model="selectedFreeTime"/>
              <label :for="formatTime(freeTime) + '_checkbox'">{{ formatTime(freeTime) }}</label>
            </div>
          </div>
        </div>
        <div class="col-6 offset-6 mt-2">
          <button @click="addEQuery" :disabled="isDisabled" class="button mt-3">{{ translations.Add }}</button>
        </div>
      </div>
    </div>
    <div v-cloak class="col-6" v-if="!validated && !succeeded" style="font-size: 28pt; text-align: center">
      {{ translations.Link_damaged }}
    </div>
    <div v-cloak class="col-8" v-if="succeeded" style="font-size: 28pt; text-align: center">
      {{ translations.Operation_successful}}
      <br /><br />
      {{translations.Equery_num + eQueryNum}}
      <br /><br />
      {{translations.Equery_start + eQueryStart }}
    </div>
  </div>
</template>
<script>
import websocket from '../../shared/websocket'
import registrationCallbacks from '../../shared/callbacks/registrationCustomer.callbacks'
import { mapGetters } from 'vuex'

export default {
  name: 'registration',
  computed: {
    ...mapGetters([
      'registrationParams',
      'translations',
      'services',
      'freeTimes',
      'validated',
      'succeeded',
      'eQueryNum',
      'eQueryStart'
    ]),
    queueDate: {
      get () {
        return this.$store.getters.queueDate
      },
      set (value) {
        this.$store.dispatch('updateQueueDate', value)
      }
    },
    selectedFreeTime: {
      get () {
        return this.$store.getters.selectedFreeTime
      },
      set (value) {
        this.$store.dispatch('updateSelectedFreeTime', value)
      }
    },
    selectedService: {
      get () {
        return this.$store.getters.selectedService
      },
      set (value) {
        this.$store.dispatch('updateSelectedService', value)
      }
    },
    isDisabled: function () {
      return this.selectedService === 0 || this.selectedFreeTime === '' ||
            (this.registrationParams.length > 0 ? this.registrationParams.every(function (param) { return !param.value }) : false)
    }
  },
  methods: {
    getFreeTime: function () {
      const docStateId = this.selectedService
      const timeWant = this.queueDate
      websocket.getEqueryPeriod(docStateId, timeWant, registrationCallbacks.getEQueryPeriodResponse)
      websocket.getDynamicsCount(docStateId, registrationCallbacks.getDynamicsCountResponse)
      websocket.getServiceParams(docStateId, registrationCallbacks.getServiceParamsResponse)
    },
    formatTime: function (freeTime) {
      return (new Date(freeTime._time_start.replace(/(\d+).(\d+).(\d+) (\d+):(\d+):(\d+)/, '$2/$1/$3 $4:$5:$6'))).toLocaleTimeString('ru', { hour: '2-digit', minute: '2-digit' })
    },
    addEQuery: function () {
      this.$store.dispatch('updateNearest', false)
      websocket.getWaitingList(registrationCallbacks)
    }
  }
}
</script>
