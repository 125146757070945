export default {
  state: {
    config: {},
    defaultConfig: false,
    translations: [],
    daysWeekShort: [],
    configLoggedOut: localStorage.getItem('config_logged_out')
  },
  mutations: {
    UPDATE_CONFIG (state, payload) {
      state.config = payload
    },
    UPDATE_TRANSLATIONS (state, payload) {
      state.translations = payload
    },
    UPDATE_DAYS_WEEK_SHORT (state) {
      state.daysWeekShort = [
        state.translations.Sun, state.translations.Mon, state.translations.Tue, state.translations.Wen,
        state.translations.Thu, state.translations.Fri, state.translations.Sat
      ]
    },
    SET_CONFIG_LOGGED_OUT (state, payload) {
      state.configLoggedOut = payload
    }
  },
  actions: {
    async updateConfig ({ state, commit }) {
      let config = false
      if (state.defaultConfig.defaultConfig) {
        localStorage.setItem('config', state.defaultConfig.defaultConfig)
        config = state.defaultConfig
      }
      if (localStorage.getItem('config') !== undefined) {
        try {
          config = (await import('../../config/' + localStorage.getItem('config') + '/config.js')).default
        } catch (e) {
          localStorage.setItem('config_logged_out', true)
          commit('SET_CONFIG_LOGGED_OUT', true)
        }
      }
      if (config) {
        commit('UPDATE_CONFIG', config)
        commit('UPDATE_TRANSLATIONS', (await import('../../translations/' + config.lang + '/' + config.lang + '.js')).default)
        commit('UPDATE_DAYS_WEEK_SHORT')
        commit('UPDATE_TEXT_BUTTONS', state.translations, { root: true })
        commit('SET_ADDITIONAL_SERVICES_TIME', state.config.additional_services_time, { root: true })
      }
    },
    updateConfigLoggedOut ({ commit }, val) {
      commit('SET_CONFIG_LOGGED_OUT', val)
    }
  },
  getters: {
    config: state => state.config,
    translations: state => state.translations,
    daysWeekShort: state => state.daysWeekShort,
    configLoggedOut: state => state.configLoggedOut
  }
}
