/* eslint no-unused-vars: 0 */
import { X2JS } from './xml2json'

export default {
  lengthInUtf8Bytes: function (str) {
    // return  new TextEncoder('utf-8').encode(str).length
    return encodeURI(str).split(/%..|./).length - 1
  },
  xml2json: function (data) {
    var xml = new X2JS()
    var json = xml.xml_str2json(data)
    return json
  },
  json2xml: function (data) {
    var xml = new X2JS()
    var json = xml.json2xml_str(data)
    return json
  },
  isdef: function (object) {
    if (typeof (object) !== 'undefined') {
      return 1
    } else {
      return 0
    }
  },
  count: function (obj) {
    var t = typeof (obj)
    var i = 0
    if (t !== 'object' || obj == null) {
      return 0
    }
    for (var k in obj) {
      i++
    }
    return i
  },
  pad: function (n) {
    return (n < 10 ? '0' : '') + n
  }
}
