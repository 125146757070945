<template>
  <div class="row operator-actions">
    <div v-for="(button,key) in data.buttons" :class="button.class" :key="key" >
      <button class="button" @click="data.buttonFirstClick" :disabled="button.disabled"
              :name="button.id" :data-clientid="data._id" :data-couponCode="data.couponCode"
              :data-toggle="button.toggle"  :data-target="button.target"  v-html="button.text">
      </button>
    </div>
  </div>
</template>
<script>
export default {
  name: 'deferred-queue-buttons',
  props: ['data']
}
</script>
