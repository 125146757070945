import Vue from 'vue'

export default {
  state: {
    chat: {
      activeOper: false,
      activeOperName: '',
      online: [],
      operators: {},
      message: {}
    },
    chatCoupons: []
  },
  mutations: {
    SET_CHAT_OPERATORS (state, payload) {
      state.chat.operators = payload
    },
    SET_CHAT_FIELD_BY_KEY (state, payload) {
      state.chat[payload.key] = payload.val
    },
    SET_CHAT_COUPONS (state, payload) {
      state.chatCoupons = payload
    },
    SET_CHAT_MESSAGE_BY_LOGIN (state, payload) {
      Vue.set(state.chat.message, payload.login, payload.val)
    },
    ATTACHE_CHAT_MESSAGE_BY_LOGIN (state, payload) {
      state.chat.message[payload.login].push(payload.val)
    }
  },
  actions: {
    updateChatOperators ({ commit }, val) {
      commit('SET_CHAT_OPERATORS', val)
    },
    updateChatCoupons ({ commit }, val) {
      commit('SET_CHAT_COUPONS', val)
    },
    updateChatField ({ commit }, val) {
      commit('SET_CHAT_FIELD_BY_KEY', val)
    },
    updateChatMessageByLogin ({ commit }, val) {
      commit('SET_CHAT_MESSAGE_BY_LOGIN', val)
    },
    pushChatMessageByLogin ({ commit }, val) {
      commit('ATTACHE_CHAT_MESSAGE_BY_LOGIN', val)
    }
  },
  getters: {
    chat: state => state.chat,
    chatCoupons: state => state.chatCoupons
  }
}
