<template>
  <div class="col-12">
    <div class="row">
      <div class=" col-6">
        <input id="queue-date" type="date" v-model="grafDate" @change="getGrafTime" class="form-control  mt-3">
      </div>
      <div class=" col-6">
        <button @click="getGrafTime" class="button mt-3 mb-3">{{ translations.buttonChart }}</button>
      </div>
    </div>
    <div v-if="windowWidth > 991" id="chart"></div>
    <div v-if="windowWidth < 992">
      <apexchart v-for="(data, index) in dataSets" v-bind:key="index"
                 type=rangeBar height='300px'
                 :options="{ ...chartOptions, ...{ title: { text: index, align: 'center' } } }"
                 :series="data" />
    </div>
  </div>
</template>
<script>
import websocket from '../../../../shared/websocket'
import chartCallbacks from '../../../../shared/callbacks/chart.callbacks'
import VueApexCharts from 'vue-apexcharts'
import $ from 'jquery'
export default {
  name: 'chart-tab',
  components: { apexchart: VueApexCharts },
  methods: {
    getGrafTime: function () {
      if (this.windowWidth > 991) {
        websocket.getGrafTime(chartCallbacks.getGrafTimeParamsResponse)
      } else {
        websocket.getGrafTime(chartCallbacks.updateChart)
      }
    }
  },
  computed: {
    translations () {
      return this.$store.getters.translations
    },
    grafDate: {
      get () {
        return this.$store.getters.grafDate
      },
      set (value) {
        this.$store.dispatch('updateGrafDate', value)
      }
    },
    series () {
      return this.$store.getters.chartSeries
    },
    chartOptions () {
      return this.$store.getters.chartOptions
    },
    dataSets () {
      return this.$store.getters.dataSets
    },
    windowWidth () {
      return $(window).width()
    }
  }
}
</script>
