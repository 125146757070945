export default {
  state: {
    serverTime: { // время сервера
      fulltime: '', // время сервера без анализа
      date: '',
      time: '',
      day: ''
    },
    operatorCurrentClient: [],
    autoTimeCurrent: { // время сервера
      minute: 1,
      second: 0
    },
    autoTime: 60,
    option: localStorage.getItem('option'), // выбранная опция
    optionMute: localStorage.getItem('optionMute') === 'true', // bool вызовы без уведомлений
    optionTime: localStorage.getItem('optionTime'), // время когда выбрали опцию
    autoMinutes: parseInt(localStorage.getItem('autominutes')) ? parseInt(localStorage.getItem('autominutes')) : 1,
    beginStart: localStorage.getItem('beginStart') === 'true',
    block: parseInt(localStorage.getItem('block')),
    onPause: localStorage.getItem('onPause') === 'true', // bool оператор на паузе
    blinkText: false
  },
  mutations: {
    SET_SERVER_TIME (state, payload) {
      state.serverTime.fulltime = payload.fulltime
      state.serverTime.date = payload.date
      state.serverTime.time = payload.time
      state.serverTime.day = payload.day
    },
    SET_AUTO_TIME (state, payload) {
      state.autoTime = payload
    },
    SET_BEGIN_START (state, payload) {
      state.beginStart = payload
    },
    SET_BLOCK (state, payload) {
      state.block = payload
    },
    SET_OPERATOR_CURRENT_CLIENT (state, payload) {
      state.operatorCurrentClient = payload
    },
    SET_OPERATOR_CURRENT_CLIENT_FIELD (state, payload) {
      state.operatorCurrentClient[payload.key] = payload.val
    },
    SET_PAUSE (state, payload) {
      state.onPause = payload
    },
    SET_OPTION_TIME (state, payload) {
      state.optionTime = payload
    },
    SET_OPTION (state, payload) {
      state.option = payload
    },
    SET_BLINK_TEXT (state, payload) {
      state.blinkText = payload
    },
    SET_AUTO_MINUTES (state, payload) {
      state.autoMinutes = payload
    },
    SET_AUTO_TIME_CURRENT (state, payload) {
      state.autoTimeCurrent = payload
    },
    SET_OPTION_MUTE (state, payload) {
      state.optionMute = payload
    }
  },
  actions: {
    updateServerTime ({ commit }, value) {
      commit('SET_SERVER_TIME', value)
    },
    updateAutoTime ({ commit }, value) {
      commit('SET_AUTO_TIME', value)
    },
    updateSetBeginStart ({ commit }, value) {
      commit('SET_BEGIN_START', value)
    },
    updateBlock ({ commit }, value) {
      commit('SET_BLOCK', value)
    },
    updateOperatorCurrentClient ({ commit }, value) {
      commit('SET_OPERATOR_CURRENT_CLIENT', value)
    },
    updateOperatorCurrentClientField ({ commit }, data) {
      commit('SET_OPERATOR_CURRENT_CLIENT_FIELD', data)
    },
    updatePause ({ commit }, value) {
      commit('SET_PAUSE', value)
    },
    updateOptionTime ({ commit }, value) {
      commit('SET_OPTION_TIME', value)
    },
    updateOption ({ commit }, value) {
      commit('SET_OPTION', value)
    },
    updateBlinkText ({ commit }, value) {
      commit('SET_BLINK_TEXT', value)
    },
    updateAutoMinutes ({ commit }, value) {
      commit('SET_AUTO_MINUTES', value)
    },
    updateAutoTimeCurrent ({ commit }, value) {
      commit('SET_AUTO_TIME_CURRENT', value)
    },
    updateOptionMute ({ commit }, value) {
      commit('SET_OPTION_MUTE', value)
    }
  },
  getters: {
    serverTime: state => state.serverTime,
    operatorCurrentClient: state => state.operatorCurrentClient,
    autoTimeCurrent: state => state.autoTimeCurrent,
    autoTime: state => state.autoTime,
    option: state => state.option,
    optionTime: state => state.optionTime,
    autoMinutes: state => state.autoMinutes,
    optionMute: state => state.optionMute,
    beginStart: state => state.beginStart,
    block: state => state.block,
    onPause: state => state.onPause,
    blinkText: state => state.blinkText
  }
}
