<template>
  <figure class="figure">
    <img :src="avatar" class="figure-img img-fluid rounded" >
    <figcaption class="figure-caption">{{name}}</figcaption>
    <figcaption class="figure-caption" v-if="fio"><small>{{fio}}</small></figcaption>
  </figure>
</template>
<script>
export default {
  name: 'avatar',
  props: ['avatar', 'name', 'fio']
}
</script>
