<template>
  <registration/>
</template>

<script>
import Registration from '../components/Customer/Registration.vue'
import websocket from '../shared/websocket'
import utils from '../shared/libs/utils'
import registrationCustomerCallbacks from '../shared/callbacks/registrationCustomer.callbacks'

export default {
  name: 'registration-customer',
  components: {
    Registration
  },
  metaInfo: {
    title: 'Регистрация в очереди',
    htmlAttrs: {
      lang: 'ru'
    }
  },
  async mounted () {
    // document.title = this.translations.Put;
    let json
    try {
      const code = decodeURIComponent(this.getGet('code'))
      json = JSON.parse(code.hexDecode().utf8Decode())
    } catch (error) {
      json = undefined
    }
    await this.$store.dispatch('updateConfig')
    await this.$store.dispatch('updateValidated', utils.isdef(json) && utils.isdef(json.clientid))

    if (this.$store.getters.validated) {
      await this.$store.dispatch('updateClientGlobalId', json.clientid)
      websocket.start = async function () {
        const result = await websocket.getBotReg()
        if (result) {
          await websocket.getWaitingList(registrationCustomerCallbacks)
        }
      }
      websocket.websocketStart(this.$store.getters.config.server)
    }
  },
  methods: {
    getGet: function (key) {
      let p = window.location.search
      p = p.match(new RegExp(key + '=([^&=]+)'))
      return p ? p[1] : false
    }
  }
}
</script>
