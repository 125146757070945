import Vue from 'vue'

export default {
  state: {
    chart: {
      series: [],
      chartOptions: {
        plotOptions: {
          bar: {
            horizontal: true
          }
        },
        yaxis: {
          min: 0,
          max: 0
        },
        xaxis: {
          type: 'datetime',
          labels: {
            datetimeUTC: false
          }
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'light',
            type: 'vertical',
            shadeIntensity: 0.25,
            gradientToColors: undefined,
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [50, 0, 100, 100]
          }
        },
        tooltip: {
          x: {
            format: 'HH:mm'
          }
        },
        legend: {
          show: true,
          itemMargin: {
            horizontal: 5,
            vertical: 5
          }
        }
      },
      dataSets: {}
    }
  },
  mutations: {
    SET_CHART_SERIES (state, payload) {
      state.chart.series = payload
    },
    SET_CHART_OPTIONS_YAXIS (state, payload) {
      state.chart.chartOptions.yaxis = payload
    },
    SET_CHAT_DATA_SETS (state, { key, val }) {
      Vue.set(state.chart.dataSets, key, val)
    },
    CLEAR_DATA_SETS (state) {
      state.chart.dataSets = {}
    }
  },
  actions: {
    updateOptionsYaxis ({ commit }, payload) {
      commit('SET_CHART_OPTIONS_YAXIS', payload)
    },
    updateChartSeries ({ commit }, payload) {
      commit('SET_CHART_SERIES', payload)
    },
    updateChartDataSets ({ commit }, payload) {
      commit('SET_CHAT_DATA_SETS', payload)
    }
  },
  getters: {
    chartSeries: state => state.chart.series,
    chartOptions: state => state.chart.chartOptions,
    dataSets: state => state.chart.dataSets
  }
}
