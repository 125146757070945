import Vue from 'vue'
import Vuex from 'vuex'
import config from './moduls/config'
import operators from './moduls/operators'
import queueData from './moduls/queueData'
import additionalData from './moduls/additionalData'
import printData from './moduls/printData'
import chat from './moduls/chat'
import queueButtons from './moduls/queueButtons'
import timeouts from './moduls/timeouts'
import sendMessageData from './moduls/sendMessageData'
import registrationData from './moduls/registrationData'
import confirmModal from './moduls/confirmModal'
import registrationModal from './moduls/registrationModal'
import moveDataModal from './moduls/moveDataModal'
import websocket from './moduls/websocket'
import chart from './moduls/chart'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    config,
    operators,
    queueData,
    additionalData,
    printData,
    chat,
    queueButtons,
    timeouts,
    sendMessageData,
    registrationData,
    confirmModal,
    registrationModal,
    moveDataModal,
    websocket,
    chart
  }
})
