export default {
  state: {
    dataConfirm: {
      clientId: false,
      docStateId: false,
      timeWant: false
    }
  },
  mutations: {
    SET_DATA_CONFIRM (state, payload) {
      state.dataConfirm = payload
    }
  },
  actions: {
    updateDataConfirm ({ commit }, payload) {
      commit('SET_DATA_CONFIRM', payload)
    }
  },
  getters: {
    dataConfirm: state => state.dataConfirm
  }
}
