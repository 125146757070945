export default {
  state: {
    moveDataModal: {
      operators: [],
      deferMinutes: 0,
      selectedOperator: 0,
      clientId: 0
    }
  },
  mutations: {
    SET_MOVE_MODAL_DATA_BY_FIELD (state, payload) {
      state.moveDataModal[payload.key] = payload.val
    }
  },
  actions: {
    updateMovedModalDataByField ({ commit }, payload) {
      commit('SET_MOVE_MODAL_DATA_BY_FIELD', payload)
    }
  },
  getters: {
    moveDataModal: state => state.moveDataModal
  }
}
