<template>
  <div>
    <div id="frame">
      <div id="sidepanel" class="non-mobile">
        <chat-contacts :changeSidebar="changeSidebar"/>
      </div>
      <div class="content" v-if="activeOper">
        <div class="contact-profile">
          <div class="changeSidebar" @click="changeSidebar"><i class="fa fa-arrow-left" aria-hidden="true"></i></div>
          <img src="../../../../assets/img/user-profile.png" alt="" />
          <p>{{ activeOperName }}</p>
        </div>
        <chat-messages/>
        <chat-message-action/>
      </div>
    </div>
  </div>
</template>
<script>
import $ from 'jquery/dist/jquery.min'
import Contacts from './Contacts'
import Messages from './Messages'
import MessageAction from './MessageAction'
export default {
  name: 'chat-tab',
  components: { 'chat-contacts': Contacts, 'chat-messages': Messages, 'chat-message-action': MessageAction },
  computed: {
    activeOper () {
      return this.$store.getters.chat.activeOper
    },
    activeOperName () {
      return this.$store.getters.chat.activeOperName
    }
  },
  methods: {
    changeSidebar: function () {
      if ($(window).width() <= 990) {
        $('#frame .content').width('calc(100% - 58px)')
        $('#sidepanel').toggleClass('non-mobile', 'fast')
        $('#frame .content').animate({ width: 'toggle' }, 0, function () {
          $('#frame .content').css('width', "'calc(100% - 58px)'")
        })
        $('#frame .content').css('display', '')
      }
    }
  }
}
</script>
