<template>
  <div id="auth-app">
    <div v-cloak v-if="!configLoggedOut && config.servid">
      <login-operator />
    </div>
    <div v-cloak v-if="configLoggedOut || !config.servid">
      <set-config/>
    </div>
  </div>
</template>
<script>
import SetConfig from '../components/Auth/SetConfig'
import LoginOperator from '../components/Auth/LoginOperator'
export default {
  name: 'auth',
  components: { SetConfig, LoginOperator },
  computed: {
    configLoggedOut () {
      return this.$store.getters.configLoggedOut
    },
    config () {
      return this.$store.getters.config
    }
  },
  metaInfo: {
    title: 'Авторизация',
    htmlAttrs: {
      lang: 'ru'
    }
  }
}
</script>
<style>
  #auth-app {
    display: inline-flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center
  }
</style>
