export default {
  state: {
    timeouts: { // хранилище таймаутов
      current_queue: '',
      current_client: '',
      deferred_queue: '',
      waiting_list: '',
      server_time: '',
      count_message: '',
      message: '',
      reg_list: '',
      intercom_list: '',
      auto_time: '',
      beep_tab: ''
    }
  },
  mutations: {
    SET_TIMEOUT_BY_KEY (state, payload) {
      state.timeouts[payload.key] = payload.val
    },
    CLEAR_TIMEOUT_BY_KEY (state, payload) {
      clearInterval(state.timeouts[payload])
    },
    CLEAR_ALL_TIMEOUTS (state) {
      Object.keys(state.timeouts)
        .forEach(key => {
          clearInterval(state.timeouts[key])
        })
    }
  },
  actions: {
    updateTimeout ({ commit }, value) {
      value = typeof value === 'undefined' ? 10000 : value
      commit('SET_TIMEOUT_BY_KEY', value)
    },
    clearTimeout ({ commit }, key) {
      commit('CLEAR_TIMEOUT_BY_KEY', key)
    },
    clearAllTimeouts ({ commit }) {
      commit('CLEAR_ALL_TIMEOUTS')
    }
  },
  getters: {
    timeouts: state => state.timeouts
  }
}
