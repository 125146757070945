<template>
  <div class="swiper-slide main-panel">
    <section id="queue-app">
      <ul v-cloak class="nav nav-tabs" role="tablist">
        <template v-if="(
          typeof config.current_tab !== 'undefined' &&
          config.current_tab) ||
           operid==1183 ||
           operid==1184 ||
           typeof config.current_tab === 'undefined'">
          <nav-item-main-panel  href="#current" id="tab_current" :view="view"
                                classes="nav-link active" :title="translations.Current_list" :count="countCurrentQueue"/>
        </template>
        <template v-else>
          <li style="padding: 1rem 0.5rem;font-size: 0.85rem;">{{translations.Current_list}} - {{countCurrentQueue}}</li>
        </template>
        <nav-item-main-panel v-if="(typeof config.deferred_tab !== 'undefined' &&  config.deferred_tab) || typeof config.deffered_tab === 'undefined'" href="#deffered" id="tab_deferred" :view="view"
                             classes="nav-link" :title="translations.Deferred" :count="countDeferredQueue"/>
        <nav-item-main-panel v-if="config.waiting_tab" href="#waiting-list-app" id="tab_waiting" :view="view"
                             classes="nav-link" :title="translations.Leaf_waiting" :count="countWaitingQueue"/>
        <nav-item-main-panel v-if="config.viber_chat"  href="#messages-app" id="tab_messages" :view="view"
                             classes="nav-link" :title="translations.Messages" :count="countMessages"/>
        <nav-item-main-panel v-if="config.intercom_chat"  href="#chat-app" id="tab_chat" :view="view" classes="nav-link"
                             :title="translations.Chat" :count="countOnline" />
        <nav-item-main-panel v-if="config.registration_tab" href="#registration-app" id="tab_registration"
                             :view="view" classes="nav-link" :title="translations.Leaf_productions"/>
        <nav-item-main-panel v-if="config.graf_tab || operid==1183 || operid==1184" href="#graf-app" classes="nav-link" :view="view"
                              :title="translations.Graf_works" id="tab_graf"/>
      </ul>
      <div v-cloak  v-load v-resize="setElHeight" class="tab-content">
        <div role="tabpanel" class="tab-pane fade show active" id="current" v-if="(typeof config.current_tab !== 'undefined' &&  config.current_tab)|| operid==1052 || operid==1055 || operid==1056 || operid==1183 || operid==1184 || typeof config.current_tab === 'undefined'">
          <current-queue :buttonFirstClick="buttonFirstClick" />
        </div>
        <div role="tabpanel" class="tab-pane fade" id="deffered" v-if="(typeof config.deffered_tab !== 'undefined' &&  config.deffered_tab) || typeof config.deffered_tab === 'undefined'">
          <deferred-queue :buttonFirstClick="buttonFirstClick" />
        </div>
        <div role="tabpanel" class="tab-pane fade" id="waiting-list-app"  v-if="config.waiting_tab" >
          <waiting-queue :buttonFirstClick="buttonFirstClick" />
        </div>
        <div role="tabpanel" class="tab-pane fade" id="messages-app" >
          <messages :buttonFirstClick="buttonFirstClick" />
        </div>
        <div role="tabpanel" class="tab-pane fade" id="chat-app" >
          <chat-tab/>
        </div>
        <div role="tabpanel" class="tab-pane fade" id="registration-app" v-if="config.registration_tab">
          <registration-tab/>
        </div>
        <div role="tabpanel" class="tab-pane" id="graf-app" v-if="config.graf_tab || operid==1183 || operid==1184">
          <chart-tab/>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
// import Table from '../../Base/Table'
import RegistrationTab from './Registration/RegistrationTab'
import CurrentQueue from './CurrentQueue/CurrentQueue'
import DeferredQueue from './DeferredQueue/DeferredQueue'
import WaitingQueue from './WaitingQueue/WaitingQueue'
import Messages from './Messages/Messages'
import ChatTab from './Chat/ChatTab'
import ChartTab from './Chart/ChartTab'
import NavItem from './NavItem'
import $ from 'jquery/dist/jquery.min.js'
import websocket from '../../../shared/websocket'
import resize from 'vue-resize-directive'

export default {
  name: 'main-panel',
  components: {
    // 'custom-table': Table,
    'registration-tab': RegistrationTab,
    'current-queue': CurrentQueue,
    'deferred-queue': DeferredQueue,
    'waiting-queue': WaitingQueue,
    messages: Messages,
    'chart-tab': ChartTab,
    'chat-tab': ChatTab,
    'nav-item-main-panel': NavItem
  },
  computed: {
    operid () {
      let operid = null
      if (this.$store.getters.currentOperator.operid) {
        operid = this.$store.getters.currentOperator.operid
      } else if (localStorage.getItem('operid')) {
        operid = localStorage.getItem('operid')
      }
      return operid
    },
    config () {
      return this.$store.getters.config
    },
    translations () {
      return this.$store.getters.translations
    },
    countCurrentQueue () {
      return this.$store.getters.countCurrentQueue
    },
    countDeferredQueue () {
      return this.$store.getters.countDeferredQueue
    },
    countWaitingQueue () {
      return this.$store.getters.countWaitingQueue
    },
    countMessages () {
      return this.$store.getters.countMessages
    },
    countOnline () {
      return this.$store.getters.countOnline
    },
    buttons () {
      return this.$store.getters.buttons
    }
  },
  methods: {
    view: async function (event) {
      const tab = event.currentTarget.getAttribute('id')
      await this.$store.dispatch('updateNewInfoTabsByTab', { tab, val: false })
      await this.$store.dispatch('updateBlinkText', false)
      $(document).mousemove()
    },
    buttonFirstClick: async function (event) {
      const id = event.currentTarget.name
      switch (id) {
        case 'forcenext':
          websocket.forcenextClient(
            event.currentTarget.getAttribute('data-clientid'),
            event.currentTarget.getAttribute('data-equery-num')
          )
          break
        case 'forcedelete':
          websocket.forceDeleteClient(
            event.currentTarget.getAttribute('data-clientid')
          )
          break
        case 'forcenexthold':
          websocket.forcenextholdClient(
            event.currentTarget.getAttribute('data-equery-num')
          )
          break
        case 'forcePrint':
          websocket.forcePrint(
            event.currentTarget.getAttribute('data-equery-num'),
            event.currentTarget.getAttribute('data-eq-start')
          )
          break
        case 'unhold': // повторный вызов
          websocket.unholdClient(event.currentTarget.getAttribute('data-clientid'))
          break
        case 'addquery': // поставить в очередь
          await this.$store.dispatch('updateRegistrationModalDataByField', {
            key: 'clientGlobalId', val: event.currentTarget.getAttribute('data-clientglobalid')
          })
          await this.$store.dispatch('updateRegistrationModalDataByField', {
            key: 'docStateId', val: event.currentTarget.getAttribute('data-docstateid')
          })
          await this.$store.dispatch('updateRegistrationModalDataByField', {
            key: 'next', val: event.currentTarget.getAttribute('data-next')
          })
          await this.$store.dispatch('updateRegistrationModalDataByField', {
            key: 'fio', val: event.currentTarget.getAttribute('data-fio')
          })
          await this.$store.dispatch('updateRegistrationModalDataByField', {
            key: 'services',
            val: this.$store.getters.services.slice().filter((service) => {
              return +service._docstateid === +event.currentTarget.getAttribute('data-next')
            })
          })
          if (this.$store.getters.registrationModalData.services.length === 1) {
            await this.$store.dispatch('updateRegistrationModalDataByField', {
              key: 'selectedService', val: this.$store.getters.registrationModalData.services[0]._docstateid
            })
            websocket.getFreeTime()
          } else if (this.$store.getters.registrationModalData.next !== false) {
            await this.$store.dispatch('updateRegistrationModalDataByField', {
              key: 'selectedService', val: this.$store.getters.registrationModalData.next
            })
            websocket.getFreeTime()
          }
          this.$bvModal.show('addquery-modal')
          break
        case 'send_message':
          await this.$store.dispatch('updateMessageDataField', { key: 'message', val: '' })
          await this.$store.dispatch('updateMessageDataField', { key: 'check', val: '' })
          await this.$store.dispatch('updateMessageDataField', { key: 'couponCode', val: event.currentTarget.dataset.couponcode })
          await this.$store.dispatch('updateMessageDataField', { key: 'to', val: 'viber' })
          if (event.currentTarget.dataset.href) {
            await this.$store.dispatch('updateMessageDataField', { key: 'href', val: event.currentTarget.dataset.href })
          }
          break
      }
      websocket.getCurrentClient()
    },
    setElHeight: function (el) {
      const height = $('#second-app').height() - Math.ceil($('#queue-app .nav-tabs').height()) - 1
      $('#queue-app > .tab-content').height(height)
      $('#frame').height(height)
    }
  },
  directives: {
    resize,
    'load' () {
      const height = $('#second-app').height() - Math.ceil($('#queue-app .nav-tabs').height()) - 1
      $('#queue-app > .tab-content').height(height)
      $('#frame').height(height)
    }
  }
}
</script>
