import Push from 'push.js'

export default {
  blinkText: function (message, title) {
    // second_data.blink_text = message;
    this.notify(message, title)
  },

  notify: function (message, title) {
    Push.create(title, {
      body: message,
      icon: '/img/icons/android-icon-144x144.png',
      link: '/',
      timeout: 60000,
      onClick: function () {
        console.log('Fired!')
        window.focus()
        this.close()
      },
      vibrate: [200, 100, 200, 100, 200, 100, 200]
    })
  }
}
