export default {
  state: {
    clientGlobalId: false,
    docStateId: false,
    next: false,
    registrationParams: [],
    validated: false,
    succeeded: false,
    nearest: false,
    nearest_count: 0,
    eQueryNum: '',
    eQueryStart: ''

    // Блок для параметров страницы добавления клиентом
    // registrationFio: false,
    // registrationServices: [],
    // registrationFreeTimes: [],
    // selectedFreeTime: '',
    // registrationSelectedService: 0,
    // registrationOperStateId: false,
    // queueDate: false,
  },
  mutations: {
    INITIAL_STATE (state) {
      state.clientGlobalId = false
      state.docStateId = false
      state.next = false
      state.registrationParams = []
      state.validated = false
      state.succeeded = false
      state.nearest = false
      state.nearest_count = 0
      state.eQueryNum = ''
      state.eQueryStart = ''
    },
    SET_REGISTRATION_PARAMS (state, payload) {
      state.registrationParams = payload
    },
    SET_NEAREST (state, payload) {
      state.nearest = payload
    },
    SET_NEAREST_COUNT (state, payload) {
      state.nearest_count = payload
    },
    SET_VALIDATED (state, payload) {
      state.validated = payload
    },
    SET_CLIENT_GLOBAL_ID (state, payload) {
      state.clientGlobalId = payload
    },
    SET_DOC_STATE_ID (state, payload) {
      state.docStateId = payload
    },
    SET_NEXT (state, payload) {
      state.next = payload
    },
    SET_REGISTRATION_FIO (state, payload) {
      state.registrationFio = payload
    },
    SET_REGISTRATION_SELECTED_SERVICE (state, payload) {
      state.registrationSelectedService = payload
    },
    SET_REGISTRATION_FREE_TIMES (state, payload) {
      state.registrationFreeTimes = payload
    },
    SET_REGISTRATION_OPER_STATE_ID (state, payload) {
      state.registrationOperStateId = payload
    },
    SET_SUCCEEDED (state, payload) {
      state.succeeded = payload
    },
    SET_EQUERY_NUM (state, payload) {
      state.eQueryNum = payload
    },
    SET_EQUERY_START (state, payload) {
      state.eQueryStart = payload
    }
  },
  actions: {
    initialRegistration ({ commit }) {
      commit('INITIAL_STATE')
    },
    updateRegistrationParams ({ commit }, val) {
      commit('SET_REGISTRATION_PARAMS', val)
    },
    updateNearest ({ commit }, val) {
      commit('SET_NEAREST', val)
    },
    updateNearestCount ({ commit }, val) {
      commit('SET_NEAREST_COUNT', val)
    },
    updateValidated ({ commit }, val) {
      commit('SET_VALIDATED', val)
    },
    updateClientGlobalId ({ commit }, val) {
      commit('SET_CLIENT_GLOBAL_ID', val)
    },
    updateDocStateId ({ commit }, val) {
      commit('SET_DOC_STATE_ID', val)
    },
    updateNext ({ commit }, val) {
      commit('SET_NEXT', val)
    },
    updateRegistrationFio ({ commit }, val) {
      commit('SET_REGISTRATION_FIO', val)
    },
    updateRegistrationSelectedService ({ commit }, val) {
      commit('SET_REGISTRATION_SELECTED_SERVICE', val)
    },
    updateRegistrationFreeTimes ({ commit }, val) {
      commit('SET_REGISTRATION_FREE_TIMES', val)
    },
    updateRegistrationOperStateId ({ commit }, val) {
      commit('SET_REGISTRATION_OPER_STATE_ID', val)
    },
    updateSucceeded ({ commit }, val) {
      commit('SET_SUCCEEDED', val)
    },
    updateEQueryNum ({ commit }, val) {
      commit('SET_EQUERY_NUM', val)
    },
    updateEQueryStart ({ commit }, val) {
      commit('SET_EQUERY_START', val)
    }
  },
  getters: {
    clientGlobalId: state => state.clientGlobalId,
    docStateId: state => state.docStateId,
    next: state => state.next,
    registrationParams: state => state.registrationParams,
    validated: state => state.validated,
    succeeded: state => state.succeeded,
    nearest: state => state.nearest,
    nearest_count: state => state.nearest_count,
    eQueryNum: state => state.eQueryNum,
    eQueryStart: state => state.eQueryStart,

    registrationFio: state => state.registrationFio,
    registrationServices: state => state.registrationServices,
    registrationFreeTimes: state => state.registrationFreeTimes,
    // selectedFreeTime: state => state.selectedFreeTime,
    registrationSelectedService: state => state.registrationSelectedService,
    registrationOperStateId: state => state.registrationOperStateId
    // queueDate: state => state.queueDate,
  }
}
