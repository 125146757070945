<template>
  <div id="contacts">
    <ul>
      <template v-for="(operator, index) in operators">
        <li v-if="operator.display"  :key="index"
            :class="operator._login === activeOper ? 'contact active' : 'contact' "
            @click="changeActiveOper" :data-login="operator._login" >
          <div class="wrap">
            <span class=" contact-status  online" v-if="online.includes(operator._login)"></span>
            <span class="contact-status  busy" v-else></span>
            <img src="../../../../assets/img/user-profile.png" :alt="operator._name" />
            <div class="meta">
              <p class="name">{{operator._name}}</p>
              <span class="chat_date" v-if="operator._chat_date">{{operator._chat_date}}</span>
              <p class="preview" v-if="operator._message">{{operator._message}}</p>
            </div>
          </div>
        </li>
      </template>
    </ul>
  </div>
</template>
<script>
import websocket from '../../../../shared/websocket'
import $ from 'jquery/dist/jquery.min'
export default {
  name: 'chat-contacts',
  props: ['changeSidebar'],
  computed: {
    operators () {
      return this.$store.getters.chat.operators
    },
    activeOper () {
      return this.$store.getters.chat.activeOper
    },
    online () {
      return this.$store.getters.chat.online
    }
  },
  methods: {
    changeActiveOper: async function (event) {
      const login = event.currentTarget.getAttribute('data-login')
      await this.$store.dispatch('updateChatField', { key: 'activeOper', val: login })
      await this.$store.dispatch('updateChatField', { key: 'activeOperName', val: websocket.getName(login) })
      if ($('#sidepanel').hasClass('non-mobile')) this.changeSidebar()
      websocket.getMessageOper(login)
    }
  }
}
</script>
