export default {
  state: {
    operators: [],
    currentOperator: {
      firstName: '',
      lastName: '',
      patronymic: '',
      sessionid: '',
      operId: '',
      userid: ''
    }
  },
  mutations: {
    UPDATE_OPERATORS (state, payload) {
      state.operators = payload
    },
    UPDATE_CURRENT_OPERATOR (state, payload) {
      state.currentOperator = payload
    }
  },
  actions: {
    updateOperators ({ commit }, operators) {
      commit('UPDATE_OPERATORS', operators)
    },
    updateCurrentOperator ({ commit }, operator) {
      localStorage.setItem('firstname', operator.firstName)
      localStorage.setItem('patronymic', operator.patronymic)
      localStorage.setItem('lastname', operator.lastName)
      commit('UPDATE_CURRENT_OPERATOR', operator)
    }
  },
  getters: {
    operators: state => state.operators,
    currentOperator: state => state.currentOperator
  }
}
