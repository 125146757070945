import store from '../../store/store'
import utils from '../libs/utils'
import registrationCustomerCallbacks from './registrationCustomer.callbacks'
import websocket from '../websocket'

export default {
  sendOrgForServices: async function (response) {
    if (+response['status-code'] === 0) {
      if (utils.isdef(response.row[0])) {
        websocket.orgid = response.row[0]._orgid
        websocket.getServices(response.row[0]._orgid, registrationCustomerCallbacks.getServicesResponse)
      } else {
        websocket.orgid = response.row._orgid
        websocket.getServices(response.row._orgid, registrationCustomerCallbacks.getServicesResponse)
      }
    }
  },

  getServicesResponse: async function (response) {
    if (+response['status-code'] === 0) {
      if (utils.isdef(response.row[0])) {
        await store.dispatch('updateServices', response.row)
      } else {
        await store.dispatch('updateServices', [response.row])
      }

      await store.dispatch('updateServices', store.getters.services.filter(
        function (service) {
          return service._docstateid === store.getters.next
        }
      ))

      if (store.getters.services.length === 1) {
        store.dispatch('updateSelectedService', store.getters.services[0]._docstateid)
        websocket.getFreeTime(registrationCustomerCallbacks)
      } else if (store.getters.next !== false) {
        store.getters.selected_service = store.getters.next
        websocket.getFreeTime(registrationCustomerCallbacks)
      } else {
        await store.dispatch('updateSelectedService', false)
        await store.dispatch('updateFreeTimes', [])
        await store.dispatch('updateRegistrationParams', [])
      }
    }
  },

  serverTimeResponse: async function (response) {
    await store.dispatch('updateFullTime', new Date(response.time))
    if (!store.getters.queueDate) {
      await store.dispatch('updateQueueDate', (new Date(store.getters.fullTime)).toDateInputValue())
    }
  },

  getEQueryPeriodResponse: async function (response) {
    if (+response['status-code'] === 0) {
      if (utils.isdef(response.row)) {
        if (utils.isdef(response.row[0])) {
          await store.dispatch('updateFreeTimes', response.row)
        } else {
          await store.dispatch('updateFreeTimes', [response.row])
        }
      } else {
        await store.dispatch('updateFreeTimes', [])
      }
    }
  },

  getServiceParamsResponse: async function (response) {
    if (+response['status-code'] === 0) {
      const elementData = response.row[0]._purpose.slice(1, -1).split(';')
      const name = elementData[0]
      let type = elementData[1]
      const label = elementData[2]
      // let value = false

      await store.dispatch('updateOperStateId', response.row[0]._operstateid)

      if (name) {
        switch (type) {
          case 's':
          default:
            type = 'text'
            // value = ''
        }
        await store.dispatch('updateRegistrationParams', [{
          name: name,
          type: type,
          label: label,
          disabled: true,
          value: store.getters.registrationFio,
          origin: response.row[0]
        }])
      } else {
        await store.dispatch('updateRegistrationParams', [])
      }
    }
  },

  calcParamResponse: function (response) {
    if (+response['status-code'] === 0) {
      if (store.getters.nearest) {
        websocket.addEquery(response.row[0]._clientid, response.row[0]._docstateid, undefined, registrationCustomerCallbacks.addedEQueryResponse)
      } else {
        websocket.addEquery(response.row[0]._clientid, response.row[0]._docstateid, store.getters.selected_free_time, registrationCustomerCallbacks.addedEQueryResponse)
      }
    }
  },

  calcParamNearestResponse: function (response) {
    if (+response['status-code'] === 0) {
      websocket.addEquery(response.row[0]._clientid, response.row[0]._docstateid)
    }
  },

  addedEQueryResponse: async function (response) {
    await store.dispatch('initialRegistration')
    await store.dispatch('initialQueue')

    await store.dispatch('updateSucceeded', response.row[0]._equery_num)
    await store.dispatch('updateEQueryNum', (new Date(response.row[0]._equery_start)).toLocaleDateString('ru') + ' ' +
      (new Date(response.row[0]._equery_start)).toLocaleTimeString('ru', { hour: '2-digit', minute: '2-digit' }))
  },

  waitingListResponse: async function (response) {
    if (+response['status-code'] === 0) {
      const waitingQueueRows = Array.isArray(response.row) ? response.row : [response.row[0]]
      var sameClient = waitingQueueRows.filter(function (client) {
        return client._id === store.getters.clientGlobalId
      })

      await store.dispatch('updateValidated', sameClient.length > 0)
      if (store.getters.validated) {
        if (store.getters.clientGlobalId &&
          store.getters.docStateId &&
          store.getters.registrationFio) {
          var docstateid = store.getters.selectedService
          var paramval = store.getters.registrationParams.length > 0 ? '<' +
            store.getters.registrationParams[0].name + '=' +
            store.getters.registrationParams[0].value + '>' : ''

          websocket.calcParam(store.getters.operStateId, docstateid, paramval, store.getters.clientGlobalId, registrationCustomerCallbacks.calcParamResponse)
        } else {
          await store.dispatch('updateDocStateId', sameClient[0]._docstateid)
          await store.dispatch('updateNext', sameClient[0]._next)
          await store.dispatch('updateRegistrationFio', (sameClient[0]._fio2) ? sameClient[0]._fio2 : sameClient[0]._fio)

          await websocket.getServerTime(registrationCustomerCallbacks)
          websocket.getOrgs(registrationCustomerCallbacks)
        }
      }
    }
  }
}
