<template>
  <div class="col-sm-12 mt-2">
    <select v-model="selectedService" @change="getFreeTime" class="form-control"  >
      <option v-for="(service,key) in services" :key="key" :value="service._docstateid">{{service._docname}}</option>
    </select>
  </div>
</template>
<script>
export default {
  name: 'registration-tab-services',
  props: ['services', 'getFreeTime'],
  computed: {
    selectedService: {
      get () {
        return this.$store.getters.selectedService
      },
      set (value) {
        this.$store.dispatch('updateSelectedService', value)
      }
    }
  }
}
</script>
