<template>
  <div class="row">
    <registration-tab-params :params="params" />
    <registration-tab-services :services="services" :getFreeTime="getFreeTime"/>
    <div class="col-sm-12 mt-3">
      <div class="row">
        <div class=" col-md-5">
          <input id="queue-date" type="date" v-model="queueDate" @change="getFreeTime" class="form-control">
        </div>
        <div class="col-md-3 mt-3  mt-md-0">
          <button @click="planEquery" :disabled="isDisabledChangeQuery" class="button">{{translations.Plan_the_time}}</button>
        </div>
        <div class="col-md-4 mt-4  mt-md-0">
          <button @click="addNearest" :disabled="isDisabledNearest" class="button">{{translations.Nearest}} <span class="badge floating">{{nearestCount}}</span></button>
        </div>
        <registration-tab-free-times :free-times="freeTimes" :format-time="formatTime" />
      </div>
    </div>
    <div class="col-md-2 mt-2">
      <button @click="addEquery" :disabled="isDisabledQuery" class="button mt-3">{{ translations.Add }}</button>
    </div>
  </div>
</template>
<script>
import Params from './Params'
import Services from './Services'
import FreeTimes from './FreeTimes'
import websocket from '../../../../../shared/websocket'
import registrationCallbacks from '../../../../../shared/callbacks/registration.callbacks'
import queueCallbacks from '../../../../../shared/callbacks/queue.callbacks'

export default {
  name: 'base-registration',
  components: {
    'registration-tab-params': Params,
    'registration-tab-services': Services,
    'registration-tab-free-times': FreeTimes
  },
  computed: {
    selectedFreeTime: {
      get () {
        return this.$store.getters.selectedFreeTime
      },
      set (value) {
        this.$store.dispatch('updateSelectedFreeTime', value)
      }
    },
    operStateId () {
      return this.$store.getters.operStateId
    },
    clientGlobalId () {
      return this.$store.getters.clientGlobalId
    },
    translations () {
      return this.$store.getters.translations
    },
    services () {
      return this.$store.getters.services
    },
    params () {
      return this.$store.getters.registrationParams
    },
    queueDate: {
      get () {
        return this.$store.getters.queueDate
      },
      set (value) {
        this.$store.dispatch('updateQueueDate', value)
      }
    },
    selectedService: {
      get () {
        return this.$store.getters.selectedService
      },
      set (value) {
        this.$store.dispatch('updateSelectedService', value)
      }
    },
    isDisabledQuery () {
      return +this.selectedService === 0 || !this.selectedFreeTime ||
        (this.params.length > 0 ? this.params.every(function (param) { return !param.value }) : false)
    },
    isDisabledChangeQuery () {
      return +this.selectedService === 0 || !this.freeTimes.length ||
        (this.params.length > 0 ? this.params.every(function (param) { return !param.value }) : false)
    },
    isDisabledNearest () {
      return !((this.params.length > 0 ? this.params.every(function (param) { return param.value }) : true) && this.$store.getters.nearest)
    },
    nearestCount () {
      return this.$store.getters.nearest_count
    },
    freeTimes () {
      return this.$store.getters.freeTimes
    },
    printData () {
      return this.$store.getters.printData
    },
    dataConfirm () {
      return this.$store.getters.dataConfirm
    },
    showPrintInfo () {
      return this.$store.getters.showPrintInfo
    }
  },
  watch: {
    printData (newValue, oldValue) {
      if (newValue.original) {
        this.$bvModal.show('print-modal')
      }
    },
    dataConfirm (newValue, oldValue) {
      if (newValue.clientId) {
        this.$bvModal.show('confirm-modal')
      }
    },
    showPrintInfo (newValue, oldValue) {
      if (newValue) {
        this.$bvModal.show('print-modal')
        this.$store.dispatch('updateShowPrintInfo', false)
      }
    }
  },
  methods: {
    getFreeTime: async function () {
      const docStateId = this.selectedService
      const timeWant = this.queueDate
      await this.$store.dispatch('updateSelectedFreeTime', false)
      websocket.getEqueryPeriod(docStateId, timeWant, registrationCallbacks.getEQueryPeriodResponse)
      websocket.getDynamicsCount(docStateId, registrationCallbacks.getDynamicsCountResponse)
      websocket.getServiceParams(docStateId, registrationCallbacks.getServiceParamsResponse)
    },
    addEquery: async function () {
      const docstateid = this.selectedService
      const paramval = this.params.length > 0 ? '<' + this.params[0].name + '=' + this.params[0].value + '>' : ''
      websocket.calcParam(this.operStateId, docstateid, paramval, false, queueCallbacks.calcParamResponse)
    },
    planEquery: async function () {
      var docstateid = this.selectedService
      var paramval = this.params.length > 0 ? '<' + this.params[0].name + '=' + this.params[0].value + '>' : ''
      websocket.calcParam(this.operStateId, docstateid, paramval, false, queueCallbacks.calcParamPlanResponse)
    },
    addNearest: function () {
      const docstateid = this.selectedService
      const paramval = this.params.length > 0 ? '<' + this.params[0].name + '=' + this.params[0].value + '>' : ''
      websocket.calcParam(this.operStateId, docstateid, paramval, this.clientGlobalId, queueCallbacks.calcParamNearestResponse)
    },
    formatTime: function (freeTime) {
      return (new Date(freeTime._time_start.replace(/(\d+).(\d+).(\d+) (\d+):(\d+):(\d+)/, '$2/$1/$3 $4:$5:$6')))
        .toLocaleTimeString('ru', { hour: '2-digit', minute: '2-digit' })
    }
  }
}
</script>
