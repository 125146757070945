<template>
  <div class="col-md-12 mt-2">
    <div v-for="(param,key) in params" :key="key">
      <label :for="'param_' + param.name">{{param.label}}</label>
      <input :id="'param_' + param.name" :type="param.type" v-model="param.value" class="form-control">
    </div>
  </div>
</template>
<script>
export default {
  name: 'registration-tab-params',
  props: ['params']
}
</script>
