<template>
  <div class="col-12">
    <base-registration v-if="additionalServices.length === 0" />
    <additional-registration v-if="additionalServices" />
  </div>
</template>
<script>
import BaseRegistration from './Base/BaseRegistration'
import AdditionalRegistration from './Additional/AdditionalRegistration'

export default {
  name: 'registration-tab',
  components: { 'base-registration': BaseRegistration, 'additional-registration': AdditionalRegistration },
  computed: {
    additionalServices () {
      return this.$store.getters.additionalServices
    }
  }
}
</script>
