<template>
  <table :class="classes">
    <thead>
    <tr>
      <th v-for="(header,hk) in headers" :key="hk" :data-before="header">{{ header }}</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="(row,kr) in rows" :key="kr">
      <td v-for="(key, index) in columns" :data-before="headers[index]" :key="index">
        <div v-if="key === 'actions'"><slot name="actions" :data="row[key]"></slot></div>
        <div v-else-if="key === 'avatar'"><slot name="avatar" :data="row[key]"></slot></div>
        <div v-else v-html="row[key]"></div>
      </td>
    </tr>
    </tbody>
  </table>
</template>
<script>
export default {
  name: 'custom-table',
  props: ['classes', 'headers', 'rows', 'columns']
}
</script>
