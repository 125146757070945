<template>
  <div class="home">
    <main class="swiper-container">
      <div class="swiper-wrapper">
        <!--Пульт управления-->
        <console-panel/>
        <!--Текущая очередь-->
        <main-panel/>
      </div>
      <button id="logout-btn-app" type="button" @click="logout" class="btn btn-primary"><span>{{ translations.Logout }}</span><i class="logout"></i></button>
      <button id="fullscreen-btn-app" type="button" @click="fullscreen" class="btn btn-primary"><span>{{ translations.Fullscreen }}</span><i class="fullscreen">✥</i></button>
      <button id="unfullscreen-btn-app" type="button" @click="unfullscreen" class="btn btn-primary d-none"><span>{{ translations.unFullscreen }}</span><i class="unfullscreen">↶</i></button>
    </main>
    <confirm-modal/>
    <print-modal/>
    <add-query-modal/>
    <send-message-modal/>
    <move-modal/>
  </div>
</template>
<script>
import MainPanel from '../components/Panel/MainPanel/MainPanel'
import ConsolePanel from '../components/Panel/ConsolePanel/ConsolePanel'
import ConfirmModal from '../components/Modals/ConfirmModal'
import PrintModal from '../components/Modals/PrintModal'
import AddQueryModal from '../components/Modals/AddQueryModal'
import SendMessageModal from '../components/Modals/SendMessageModal'
import MoveModal from '../components/Modals/MoveModal'
import app from '../App'
import websocket from '../shared/websocket'

export default {
  name: 'home',
  components: { ConsolePanel, MainPanel, ConfirmModal, PrintModal, AddQueryModal, SendMessageModal, MoveModal },
  computed: {
    translations () {
      return this.$store.getters.translations
    }
  },
  metaInfo: {
    title: 'WEB пульт',
    htmlAttrs: {
      lang: 'ru'
    }
  },
  activated () {
    app.methods.initSwiper()
    window.onresize = function () {
      app.methods.initSwiper()
    }
    this.$store.dispatch('updateConfig').then(() => {
      const config = this.$store.getters.config
      if (typeof config.server !== 'undefined') {
        websocket.websocketStart(config.server)
      }
    })
  },
  methods: {
    logout: async function () {
      const config = localStorage.getItem('config')
      const configLoggedOut = localStorage.getItem('config_logged_out')
      localStorage.clear()
      if (config) {
        localStorage.setItem('config', config)
      }
      if (configLoggedOut) {
        localStorage.setItem('config_logged_out', configLoggedOut)
      }
      await this.$store.dispatch('clearAllTimeouts')
      await this.$router.push({ name: 'auth' })
    },
    fullscreen: function () {
      document.getElementById('fullscreen-btn-app').classList.add('d-none')
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen()
      } else if (document.documentElement.msRequestFullscreen) {
        document.documentElement.msRequestFullscreen()
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen()
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT)
      }
      document.getElementById('unfullscreen-btn-app').classList.remove('d-none')
    },
    unfullscreen: function () {
      document.getElementById('unfullscreen-btn-app').classList.add('d-none')
      if (document.exitFullscreen) {
        document.exitFullscreen()
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen()
      }
      document.getElementById('fullscreen-btn-app').classList.remove('d-none')
    }
  }
}
</script>
