<template>
  <custom-table
    :classes="'table table-responsive tab-table table-responsive-unique'"
    :headers="headers"
    :rows="rows"
    :columns="columns"
  >
    <template v-slot:actions="slotProps">
      <div class="row operator-actions" v-if="slotProps.data.type ==='from'">
        <div class="col" >
          <button class="button" @click="slotProps.data.buttonFirstClick" name="send_message"
                  :data-couponCode="slotProps.data.couponCode"  data-toggle="modal"  data-target="#send-message-modal">
            <i class="send"></i></button>
        </div>
      </div>
    </template>
    <template v-slot:avatar="slotProps">
      <avatar :avatar="slotProps.data.avatar" :name="slotProps.data.name" :fio="slotProps.data.fio"/>
    </template>
  </custom-table>
</template>
<script>
import Table from '../../../Base/Table'
import Avatar from './Avatar'
export default {
  name: 'messages',
  components: { 'custom-table': Table, avatar: Avatar },
  props: ['buttonFirstClick'],
  data () {
    return { columns: ['type', 'avatar', 'message', 'equery_num', 'actions'] }
  },
  computed: {
    translations () {
      return this.$store.getters.translations
    },
    headers () {
      return [
        this.translations.Time, this.translations.FIO, this.translations.Message,
        this.translations.Ticket, this.translations.Action
      ]
    },
    rows () {
      const rows = this.$store.getters.messageRows
      return rows.map(row => {
        const rowTime = (new Date('1970/01/01 ' + row.time)).toLocaleTimeString('ru', { hour: '2-digit', minute: '2-digit' })
        const rowDate = (new Date(row.date)).toLocaleString('ru', { day: '2-digit', month: '2-digit', year: 'numeric' })
        return {
          type: `${row.type}${(rowTime) ? (', ' + rowTime) : ''}`,
          avatar: { avatar: row.avatar, name: row.name, fio: row.fio },
          message: row.message,
          equery_num: `${row.equery_num}, ${rowDate}`,
          actions: { ...row, buttonFirstClick: this.buttonFirstClick }
        }
      })
    }
  }
}
</script>
