<template>
  <b-modal id="confirm-modal">
    {{translations.Time_want}}{{dataConfirm.timeWant | confirmDateTimeFormat}}
    <template v-slot:modal-footer>
      <b-button variant="primary" @click="confirm">{{ translations.Confirm }}</b-button>
      <b-button @click="closeModal">{{ translations.Close }}</b-button>
    </template>
  </b-modal>
</template>
<script>
import websocket from '../../shared/websocket'
export default {
  name: 'confirm-modal',
  methods: {
    confirm: function () {
      this.closeModal()
      websocket.addEquery(this.dataConfirm.clientId, this.dataConfirm.docStateId)
      websocket.getEquery()
    },
    closeModal: function () {
      this.$bvModal.hide('confirm-modal')
    }
  },
  filters: {
    confirmDateTimeFormat: function (value) {
      return (new Date(value)).toLocaleString('ru', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' })
    }
  },
  computed: {
    translations () {
      return this.$store.getters.translations
    },
    dataConfirm () {
      return this.$store.getters.dataConfirm
    }
  }
}
</script>
