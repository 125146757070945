import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import Auth from './views/Auth'
import RegistrationCustomer from './views/RegistrationCustomer'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/registration_customer',
      name: 'registration_customer',
      component: RegistrationCustomer
    },
    {
      path: '/auth',
      name: 'auth',
      component: Auth
    },
    {
      path: '*',
      beforeEnter: (to, from, next) => {
        next('/')
      }
    }
  ]
})

router.beforeEach((to, from, next) => {
  const check = localStorage.getItem('sessionid') &&
                localStorage.getItem('operid') &&
                localStorage.getItem('userid') &&
                localStorage.getItem('config')
  if (!check && to.path !== '/auth') {
    next('/auth')
  } else {
    next()
  }
})

export default router
