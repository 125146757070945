
import utils from '../libs/utils'
import queueCallbacks from './queue.callbacks'
import router from '../../router'
import websocket from '../websocket'
import store from '../../store/store.js'

export default {
  checkAuthentication: async function (response) {
    // Получение ответа, залогинен ли пользователь
    const isAuthenticated = utils.isdef(response) && !+response['status-code']
    let redirectionUrl = false
    if (isAuthenticated) {
      switch (router.currentRoute.name) {
        case 'home':
          websocket.initHomeRequests()
          await queueCallbacks.queueRowsResponse(response)
          // запуск скриптов мидла
          websocket.getMiddle()
          break
        case 'auth':
          // redirectionUrl = router.resolve({ name: 'home' }).location.name
          window.location.href = '/home'
          break
      }
      // redirection_url = this.vue.$router.resolve({name: 'home'}).location.name;
    } else {
      switch (router.currentRoute.name) {
        case 'home':
          redirectionUrl = router.resolve({ name: 'auth' }).location.name
          break
        case 'auth':
          websocket.getOper(true)
          break
      }
      // this.getOper();
    }
    if (router.currentRoute.name !== redirectionUrl && redirectionUrl) {
      await router.push({ name: redirectionUrl })
    }
  },
  userCheckResponse: async function (response) {
    if (+response['status-code'] === 0) {
      localStorage.setItem('sessionid', response.row[0]._sessionid)
      localStorage.setItem('operid', response.row[0]._operid)
      localStorage.setItem('userid', response.row[0]._userid)
      await store.dispatch('updateCurrentOperator', {
        firstName: response.row[0]._firstname,
        lastName: response.row[0]._lastname,
        patronymic: response.row[0]._patronymic,
        sessionid: response.row[0]._sessionid,
        operid: response.row[0]._operid,
        userid: response.row[0]._userid
      })

      localStorage.setItem('isglobal', response.row[0]._isglobal)
      localStorage.setItem('usertype', response.row[0]._usertype)
      if (router.currentRoute.name !== router.resolve({ name: 'home' }).location.name) {
        await router.push({ name: 'home' })
        // window.location.href = '/home'
      }
    } else {
      if (router.currentRoute.name !== router.resolve({ name: 'auth' }).location.name) {
        await router.push({ name: 'auth' })
      }
    }
  }
}
