import store from '../../store/store'
import websocket from '../websocket'
import moment from 'moment'
import dateService from '../services/date.service'

export default {
  countMessageRowsResponse: async function (response) {
    await store.dispatch('updateCountMessage', response.data.count)
    if (store.getters.config.wait_time.count_message > 0) {
      await store.dispatch('updateTimeout', {
        key: 'count_message',
        val: setTimeout(() => websocket.getCountMessage(), store.getters.config.wait_time.count_message)
      })
    }
  },

  messageOperRowsResponse: async function (response) {
    if (response.data.messages.length) {
      for (const k in response.data.messages) {
        const message = response.data.messages[k]
        message.message = decodeURI(message.message)
        const time = message.time.replace(new RegExp('Z', 'g'), '')
        message.date = moment(time).format('L')
        message.time = moment(time).format('LT')
      }
      await store.dispatch('updateChatMessageByLogin', {
        login: response.data.messages[0].identifier2,
        val: response.data.messages
      })
    }
  },

  messageRowResponse: async function (response) {
    const messages = []
    for (const k in response.data.messages) {
      const message = response.data.messages[k]
      const data = dateService.fromUIDToEq(message.couponCode)
      message.equery_num = data.num
      message.message = decodeURI(message.message)
      const time = message.time.replace(new RegExp('Z', 'g'), '')
      message.date = moment(time).format('L')
      message.time = moment(time).format('LT')
      messages.push(message)
    }
    await store.dispatch('updateMessageRows', messages)
    if (store.getters.config.wait_time.message > 0) {
      await store.dispatch('updateTimeout', {
        key: 'message',
        val: setTimeout(() => websocket.getMessage(), store.getters.config.wait_time.message)
      })
    }
  },

  sendMessageBotResponse: function (response, params) {
    websocket.getMessageOper(params.data.identifier)
  },

  intercomListRowsResponse: async function (response) {
    await store.dispatch('updateChatField', {
      key: 'online',
      val: response.data.identifiers
    })
  }
}
