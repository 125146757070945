export default {
  state: {
    printData: {
      checkData: {
        docstate: false,
        equeryNum: false,
        equeryDate: false,
        equeryTime: false,
        equeryDateStart: false,
        equeryTimeStart: false,
        equeryDateEnd: false,
        equeryTimeEnd: false
      },
      original: false
    },
    showPrintInfo: false
  },
  mutations: {
    SET_PRINT_STATE (state, payload) {
      state.printData = payload
    },
    SET_EQUERY_NUM (state, payload) {
      state.printData.checkData.equeryNum = payload
    },
    SET_EQUERY_TIME (state, payload) {
      state.printData.checkData.equeryTime = payload
    },
    SET_EQUERY_TIME_START (state, payload) {
      state.printData.checkData.equeryTimeStart = payload
    },
    SET_ORIGINAL (state, payload) {
      state.printData.original = payload
    },
    SET_SHOW_PRINT_INFO (state, payload) {
      state.showPrintInfo = payload
    }
  },
  actions: {
    updatePrintState ({ commit }, payload) {
      commit('SET_PRINT_STATE', payload)
    },
    updateEQueryTime ({ commit }, payload) {
      commit('SET_EQUERY_TIME', payload)
      commit('SET_EQUERY_TIME_START', payload)
    },
    updateOriginal ({ commit }, payload) {
      commit('SET_ORIGINAL', payload)
    },
    updateEQueryNum ({ commit }, payload) {
      commit('SET_EQUERY_NUM', payload)
    },
    updateShowPrintInfo ({ commit }, payload) {
      commit('SET_SHOW_PRINT_INFO', payload)
    }
  },
  getters: {
    printData: state => state.printData,
    showPrintInfo: state => state.showPrintInfo
  }
}
