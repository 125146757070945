<template>
  <div id="app">
    <keep-alive>
      <router-view/>
    </keep-alive>
  </div>
</template>
<script>
import $ from 'jquery'
import Swiper from 'swiper'

export default {
  metaInfo: {
    meta: [{
      vmid: 'viewport',
      name: 'viewport',
      content: 'width=device-width, user-scalable=no'
    }]
  },
  mounted () {
    this.initSwiper()
    $(window).on('resize', () => {
      this.initSwiper()
    })
  },
  data () {
    return {
      mySwiper: undefined
    }
  },
  methods: {
    initSwiper: function () {
      const screenWidth = $(window).width()
      if (this.mySwiper !== undefined) {
        this.mySwiper.destroy()
        this.mySwiper = undefined
        $('.swiper-wrapper').removeAttr('style')
        $('.swiper-slide').removeAttr('style')
      }
      if (screenWidth < 992 && this.mySwiper === undefined) {
        this.mySwiper = new Swiper('.swiper-container', {
          spaceBetween: 5,
          slidesPerView: 1,
          updateOnWindowResize: true,
          watchOverflow: true,
          centeredSlides: true,
          pagination: {
            el: '.swiper-pagination'
          }
        })
      } else if (screenWidth > 991 && this.mySwiper !== undefined) {
        this.mySwiper.destroy()
        this.mySwiper = undefined
        $('.swiper-wrapper').removeAttr('style')
        $('.swiper-slide').removeAttr('style')
      }
    }
  }
}
</script>
