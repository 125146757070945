<template>
  <b-modal id="addquery-modal">
    <div class="col-12">
      <div class="row">
        <div class="col-md-12 mt-2">
          <div v-for="(param,index) in registrationModalData.params" :key="index">
            <label :for="'param_' + param.name">{{param.label}}</label>
            <input :disabled="param.disabled" :id="'param_' + param.name" :type="param.type" v-model="param.value" class="form-control">
          </div>
        </div>
        <div class="col-sm-12 mt-2">
          <select v-model="registrationModalData.selectedService" @change="getFreeTime" class="form-control" >
            <option v-for="service in registrationModalData.services" :key="service._docstateid" :value="service._docstateid">{{service._docname}}</option>
          </select>
        </div>
        <div class="col-sm-12 mt-2">
          <input type="date" v-model="registrationModalData.queueDate" @change="getFreeTime" class="form-control w-50">
          <div class="radios-as-buttons mt-2">
            <div v-for="(freeTime,index) in registrationModalData.freeTimes" :key="index">
              <input :id="formatTime(freeTime._time_start) + '_checkbox'" type="radio" :value="freeTime._time_start" v-model="registrationModalData.selectedFreeTime"/>
              <label :for="formatTime(freeTime._time_start) + '_checkbox'">{{ formatTime(freeTime._time_start) }}</label>
            </div>
          </div>
        </div>
        <div class="col-md-6 mt-2">
          <button @click="addEquery" :disabled="isDisabledEQuery" class="button mt-3" data-dismiss="modal">{{ translations.Add }}</button>
        </div>
        <div class="col-md-6 mt-2">
          <button @click="addNearest" :disabled="isDisabledNearest" class="button mt-3" data-dismiss="modal">{{ translations.Nearest }}</button>
        </div>
      </div>
    </div>
    <template v-slot:modal-footer>
      <b-button @click="closeModal">{{ translations.Close }}</b-button>
    </template>
  </b-modal>
</template>
<script>
import websocket from '../../shared/websocket'
import queueCallbacks from '../../shared/callbacks/queue.callbacks.js'

export default {
  name: 'add-query-modal',
  methods: {
    addEquery: function () {
      const docstateid = this.registrationModalData.selectedService
      const paramval = this.registrationModalData.params.length > 0 ? '<' + this.registrationModalData.params[0].name +
        '=' + this.registrationModalData.params[0].value + '>' : ''
      websocket.calcParam(this.registrationModalData.operStateId, docstateid, paramval,
        this.registrationModalData.clientGlobalId, queueCallbacks.calcParamModalResponse)
      websocket.getWaitingList()
      this.closeModal()
    },
    addNearest: function () {
      const docstateid = this.registrationModalData.selectedService
      const paramval = this.registrationModalData.params.length > 0 ? '<' + this.registrationModalData.params[0].name +
        '=' + this.registrationModalData.params[0].value + '>' : ''
      websocket.calcParam(this.registrationModalData.operStateId, docstateid, paramval,
        this.registrationModalData.clientGlobalId, queueCallbacks.calcParamNearestResponse)
      websocket.getWaitingList()
      this.closeModal()
    },
    formatTime: function (time) {
      return (new Date(time.replace(/(\d+).(\d+).(\d+) (\d+):(\d+):(\d+)/, '$2/$1/$3 $4:$5:$6'))).toLocaleTimeString('ru', { hour: '2-digit', minute: '2-digit' })
    },
    closeModal: function () {
      this.$bvModal.hide('addquery-modal')
    },
    getFreeTime: function () {
      websocket.getFreeTime()
    }
  },
  computed: {
    translations () {
      return this.$store.getters.translations
    },
    registrationModalData () {
      return this.$store.getters.registrationModalData
    },
    isDisabledEQuery: function () {
      return this.registrationModalData.selectedService === 0 || this.registrationModalData.selectedFreeTime === '' ||
        (this.registrationModalData.params.length > 0
          ? this.registrationModalData.params.every(function (param) { return !param.value }) : false)
    },
    isDisabledNearest: function () {
      // console.log(this.registrationModalData.params.every(function (param) { return !param.value }))
      return this.registrationModalData.selectedService === 0 || this.registrationModalData.freeTimes.length === 0 ||
        (this.registrationModalData.params.length > 0
          ? this.registrationModalData.params.every(function (param) { return !param.value }) : false)
    },
    dataConfirm: function () {
      return this.$store.getters.dataConfirm
    }
  },
  watch: {
    dataConfirm (newValue, oldValue) {
      if (newValue.clientId) {
        this.$bvModal.show('confirm-modal')
      }
    }
  }
}
</script>
