export default {
  state: {
    sendMessageData: {
      message: '',
      check: '',
      to: '',
      href: '',
      couponCode: ''
    }
  },
  mutations: {
    SET_MESSAGE_DATA_FIELD (state, payload) {
      state.sendMessageData[payload.key] = payload.val
    }
  },
  actions: {
    updateMessageDataField ({ commit }, val) {
      commit('SET_MESSAGE_DATA_FIELD', val)
    }
  },
  getters: {
    sendMessageData: state => state.sendMessageData
  }
}
