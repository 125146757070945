<template>
  <div class="col-12 mt-3">
    <div class="radios-as-buttons ">
      <div v-for="(freeTime,key) in freeTimes" :key="key">
        <input :id="formatTime(freeTime) + '_checkbox'" type="radio" :value="freeTime._time_start" v-model="selectedFreeTime"/>
        <label :for="formatTime(freeTime) + '_checkbox'">{{ formatTime(freeTime) }}</label>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'registration-tab-free-times',
  props: ['freeTimes', 'formatTime'],
  computed: {
    selectedFreeTime: {
      get () {
        return this.$store.getters.selectedFreeTime
      },
      set (value) {
        this.$store.dispatch('updateSelectedFreeTime', value)
      }
    }
  }
}
</script>
