<template>
  <div v-cloak class="col-12 px-0 ">
    <div class="dropdown">
      <button type="button" data-boundary="viewport" class="dropdown-toggle dropdown-settings" id="dropdownMenuOptions"  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <i class="settings"></i> {{ translations.Options }}
      </button>
      <div class="dropdown-menu" aria-labelledby="dropdownMenuOptions">
        <form class="p-3" id="form-options">
          <div>
            <input type="checkbox" @click="muteClick" :checked="optionMute" id="mute">
            <label for="mute">{{ translations.Without_invitation }}</label>
          </div>
          <div>
            <input type="radio" @click="optionClick"  name="option" value="autominutes" v-model="option" id="autominutes">
            <label for="autominutes">
              {{ translations.Automatic }}
              <button class="small" @click="valueMinutes" value="-5">&#8211;</button>
              <input type="text" name="minutes" id="valueMinutes" class="input-inline" :value="autoMinutes">
              <button class="small" @click="valueMinutes" value="+5">+</button>
              {{ translations.minutes }}</label>
          </div>
          <div>
            <input type="radio"  @click="optionClick" name="option" v-model="option" value="autocall" id="autocall">
            <label for="autocall">{{ translations.Auto_call }}</label>
          </div>
          <div>
            <input type="radio"  @click="optionClick" name="option" v-model="option"  value="autostart" id="autostart">
            <label for="autostart">{{ translations.Auto_start }}</label>
          </div>
          <div>
            <input type="radio" @click="optionClick"  name="option" v-model="option"  value="off" id="off">
            <label for="off">{{ translations.Off }}</label>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import utils from '../../../shared/libs/utils'
import $ from 'jquery/dist/jquery.min.js'
export default {
  name: 'setting',
  computed: {
    serverTime () {
      return this.$store.getters.serverTime
    },
    option: {
      get () {
        return this.$store.getters.option
      },
      set (value) {
        this.$store.dispatch('updateOption', value)
      }
    },
    optionMute () {
      return this.$store.getters.optionMute
    },
    autoMinutes () {
      return parseInt(this.$store.getters.autoMinutes) ? parseInt(this.$store.getters.autoMinutes) : 1
    },
    translations () {
      return this.$store.getters.translations
    },
    autoTime: {
      get () {
        return this.$store.getters.autoTime
      },
      set (value) {
        this.$store.dispatch('updateAutoTime', value)
      }
    }
  },
  methods: {
    muteClick: async function (event) {
      const val = event.currentTarget.checked
      localStorage.setItem('optionMute', val)
      await this.$store.dispatch('updateOptionMute', val)
    },
    optionClick: async function (event) {
      const value = event.currentTarget.value
      localStorage.setItem('option', value)
      localStorage.setItem('optionTime', this.$store.getters.serverTime.fulltime)
      await this.$store.dispatch('updateOptionTime', this.$store.getters.serverTime.fulltime)
      this.autoTime--
    },
    valueMinutes: async function (event) {
      event.preventDefault()
      const value = event.currentTarget.value
      const curVal = $('#valueMinutes').val()
      let changeVal = parseInt(curVal) + parseInt(value)
      if (changeVal < 0) {
        changeVal = 0
      }
      localStorage.setItem('autominutes', changeVal)
      await this.$store.dispatch('updateOptionTime', this.$store.getters.serverTime.fulltime)
      localStorage.setItem('optionTime', this.$store.getters.serverTime.fulltime)
      await this.$store.dispatch('updateAutoMinutes', changeVal)
    }
  },
  watch: {
    autoTime: async function (val) {
      if (val <= 0) {
        if (this.$store.getters.beginStart) {
          await this.$store.dispatch('updateAutoTime', 0)
        } else {
          await this.$store.dispatch('updateAutoTime', 60)
        }
      }
      await this.$store.dispatch('updateAutoTimeCurrent', {
        minute: utils.pad(parseInt(this.$store.getters.autoTime / 60)),
        second: utils.pad(parseInt(this.$store.getters.autoTime % 60))
      })
    }
  }
}
</script>
