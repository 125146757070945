/* eslint no-undef: 0 */
import TimelinesChart from '../libs/timelines-chart'
import store from '../../store/store'
import chartCallbacks from './chart.callbacks.js'

export default {
  getGrafTimeParamsResponse: function (response) {
    const data = []
    var chart = document.getElementById('chart')
    if (+response['status-code'] === 0) {
      chart.innerHTML = null
      let width = chart.clientWidth
      const dwidth = document.body.clientWidth
      for (var k in response.Oper) {
        var Oper = response.Oper[k]

        var odata = []
        var alabelAfter = {}
        var timeRangeAfter = []
        var labelAfter = store.getters.translations.chartWorkAfter
        var alabel = {}
        var timeRange = []
        var label = store.getters.translations.chartWorkDay
        var alabelBefore = {}
        var timeRangeBefore = []
        var labelBefore = store.getters.translations.chartWorkBefore
        var startDay
        for (var i in Oper.row) {
          var time = {}
          var row = Oper.row[i]
          var start = row._startPeriod.replace(new RegExp('Z', 'g'), '').replace(/(\d+):(\d+):(\d+) (\d+).(\d+).(\d+)/, '$5.$4.$6 $1:$2:$3')
          var end = row._endPeriod.replace(new RegExp('Z', 'g'), '').replace(/(\d+):(\d+):(\d+) (\d+).(\d+).(\d+)/, '$5.$4.$6 $1:$2:$3')

          var val, info
          var t = []
          var typeRange = 'default'
          switch (row._type) {
            case 'start':
              typeRange = 'all'
              val = store.getters.translations.chartStartDay // 'Начало дня'
              info = ''
              startDay = end
              t = [new Date(end), new Date(end)]
              break
            case 'current_time':
              typeRange = 'all'
              val = store.getters.translations.chartCurrent // 'Обслуженно'
              info = ''
              t = [new Date(startDay), new Date(end)]
              time.timeRange = t
              time.val = val
              time.info = info

              timeRange.push(time)
              val = store.getters.translations.chartCurrentTime // 'Текущее время'
              info = ''
              t = [new Date(end), new Date(end)]
              break
            case 'end':
              typeRange = 'all'
              val = store.getters.translations.chartEndDay // 'Конец дня'
              info = ''
              t = [new Date(start), new Date(start)]
              break
            case 'work':
              val = row.equery._Service
              info = row.equery._fio + ', ' + row.equery._eqNumber
              t = [new Date(start), new Date(end)]
              switch (row.equery._status) {
                case undefined:
                case '0':
                  typeRange = 'default'
                  break
                case '6':
                  typeRange = 'after'
                  break
                default:
                  typeRange = 'before'
                  break
              }
              break
            case 'break':
              val = store.getters.translations.chartBreak // 'Перерыв'
              info = ''
              t = [new Date(start), new Date(end)]
              break
          }
          time.timeRange = t
          time.val = val
          time.info = info

          switch (typeRange) {
            case 'all':
              timeRangeBefore.push(time)
              timeRange.push(time)
              timeRangeAfter.push(time)
              break
            case 'after':
              timeRangeAfter.push(time)
              break
            case 'before':
              timeRangeBefore.push(time)
              break
            default:
              timeRange.push(time)
              break
          }
        }

        alabelBefore.data = timeRangeBefore
        alabelBefore.label = labelBefore
        alabel.data = timeRange
        alabel.label = label
        alabelAfter.data = timeRangeAfter
        alabelAfter.label = labelAfter
        odata.push(alabelBefore)
        odata.push(alabel)
        odata.push(alabelAfter)
        var group = {}
        group.data = odata
        group.group = Oper._operName
        data.push(group)
      }
      let maxLineHeight = 20
      if (dwidth < 990) {
        width = 990
        maxLineHeight = 12
      }
      TimelinesChart()
        .data(data).width(width).maxLineHeight(maxLineHeight)
        .zQualitative(true)(chart)
    } else {
      chart.innerHTML = response['status-detail']
    }
  },

  updateChart: async function (response) {
    console.log(response)
    store.commit('CLEAR_DATA_SETS')
    if (+response['status-code'] === 0) {
      let data = []
      let rangeMinMax = { min: 0, max: 0 }
      for (const item of response.Oper) {
        for (const action of item.row) {
          const start = new Date(action._startPeriod.replace(new RegExp('Z', 'g'), '')
            .replace(/(\d+):(\d+):(\d+) (\d+).(\d+).(\d+)/, '$5.$4.$6 $1:$2:$3')).getTime()
          let end = new Date(action._endPeriod.replace(new RegExp('Z', 'g'), '')
            .replace(/(\d+):(\d+):(\d+) (\d+).(\d+).(\d+)/, '$5.$4.$6 $1:$2:$3')).getTime()
          switch (action._type) {
            case 'start':
              chartCallbacks.fillData(data, item, store.getters.translations.chartStartDay, '0', end, (end + (60 * 1000)))
              chartCallbacks.fillData(data, item, store.getters.translations.chartStartDay, '6', end, (end + (60 * 1000)))
              chartCallbacks.fillData(data, item, store.getters.translations.chartStartDay, '4', end, (end + (60 * 1000)))
              rangeMinMax = chartCallbacks.calculateMinMax(rangeMinMax.min, rangeMinMax.max, end, (end + (60 * 1000)))
              break
            case 'current_time':
              chartCallbacks.fillData(data, item, store.getters.translations.chartCurrentTime, '0', end, (end + (60 * 1000)))
              chartCallbacks.fillData(data, item, store.getters.translations.chartCurrentTime, '6', end, (end + (60 * 1000)))
              chartCallbacks.fillData(data, item, store.getters.translations.chartCurrentTime, '4', end, (end + (60 * 1000)))
              rangeMinMax = chartCallbacks.calculateMinMax(rangeMinMax.min, rangeMinMax.max, end, (end + (60 * 1000)))
              break
            case 'end':
              chartCallbacks.fillData(data, item, store.getters.translations.chartEndDay, '0', start, (start - (60 * 1000)))
              chartCallbacks.fillData(data, item, store.getters.translations.chartEndDay, '6', start, (start - (60 * 1000)))
              chartCallbacks.fillData(data, item, store.getters.translations.chartEndDay, '4', start, (start - (60 * 1000)))
              rangeMinMax = chartCallbacks.calculateMinMax(rangeMinMax.min, rangeMinMax.max, start, start)
              break
            case 'work':
              end = (end - start) < (60 * 1000) ? (end + (60 * 1000)) : end
              chartCallbacks.fillData(data, item, action.equery._Service, action.equery._status, start, end)
              rangeMinMax = chartCallbacks.calculateMinMax(rangeMinMax.min, rangeMinMax.max, start, end)
              break
            case 'break':
              end = (end - start) < (60 * 1000) ? (end + (60 * 1000)) : end
              chartCallbacks.fillData(data, item, store.getters.translations.chartBreak, '0', start, end)
              chartCallbacks.fillData(data, item, store.getters.translations.chartBreak, '6', start, end)
              chartCallbacks.fillData(data, item, store.getters.translations.chartBreak, '4', start, end)
              rangeMinMax = chartCallbacks.calculateMinMax(rangeMinMax.min, rangeMinMax.max, start, end)
              break
          }
        }
        await store.dispatch('updateChartDataSets', { key: item._operName, val: data })
        data = []
      }
      await store.dispatch('updateOptionsYaxis', rangeMinMax)
    }
  },

  fillData (data, operator, type, status, start, end) {
    let existType = data.find(item => item.name === type)
    if (!existType) {
      existType = { name: type, data: [] }
      data.push(existType)
    }
    existType.data.push({
      x: chartCallbacks.getNameQueue(status),
      y: [start, end]
    })
  },

  getNameQueue (status) {
    let nameQueue = ''
    switch (status) {
      case '0':
        nameQueue = store.getters.translations.chartWorkDay
        break
      case '6':
        nameQueue = store.getters.translations.chartWorkAfter
        break
      default:
        nameQueue = store.getters.translations.chartWorkBefore
        break
    }
    return nameQueue
  },

  calculateMinMax (min, max, start, end) {
    return {
      min: (min === 0 || min > start) ? start : min,
      max: (max === 0 || max < end) ? end : max
    }
  }
}
