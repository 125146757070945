import store from '../../store/store'
import utils from '../libs/utils'
import moment from 'moment'
import websocket from '../websocket'
import dateService from '../services/date.service'
import queueCallbacks from './queue.callbacks.js'

export default {
  serverTimeResponse: async function (response) {
    if (typeof response !== 'undefined' && typeof response.time !== 'undefined') {
      let dateString = response.time
      const re = new RegExp('\\s', 'g')
      dateString = dateString.replace(re, 'T')
      const serverTime = new Date(dateString)
      switch (store.getters.option) {
        case 'autominutes':
          await websocket.checkAutoMinutes(serverTime)
          break
        case 'autocall':
          websocket.checkAutoCall()
          break
        case 'autostart':
          websocket.checkAutoStart()
          break
      }

      await store.dispatch('updateFullTime', serverTime)
      if (!store.getters.queueDate) {
        await store.dispatch('updateQueueDate', (new Date(store.getters.fullTime)).toDateInputValue())
      }

      if (!store.getters.registrationModalData.queueDate) {
        await store.dispatch('updateRegistrationModalDataByField', {
          key: 'queueDate', val: (new Date(store.getters.fullTime)).toDateInputValue()
        })
      }

      await store.dispatch('updateServerTime', {
        time: serverTime.toLocaleTimeString('ru', { hour: '2-digit', minute: '2-digit' }),
        fulltime: response.time,
        date: serverTime.toLocaleDateString('ru', { day: '2-digit', month: '2-digit', year: '2-digit' }),
        day: store.getters.daysWeekShort[serverTime.getDay()]
      })
    }

    if (!store.getters.grafDate) {
      await store.dispatch('updateGrafDate', (new Date(store.getters.fullTime)).toDateInputValue())
    }

    // в ответе запуск по таймауту запроса
    if (store.getters.config.wait_time.server_time > 0) {
      await store.dispatch('updateTimeout', {
        key: 'server_time',
        val: setTimeout(() => websocket.getServerTime(), store.getters.config.wait_time.server_time)
      })
    }
  },
  addedEQueryPlanResponse1: async function (response) {
    if (utils.isdef(response)) {
      await store.dispatch('initialQueue')
      await store.dispatch('updateSelectedAdditionalServicesField', {
        key: 'clientid',
        val: response.row[0]._clientid
      })
      const eqDate = response.row[0]._equery_start.replace(new RegExp('Z', 'g'), '')
      const eqDateEnd = response.row[0]._equery_end.replace(new RegExp('Z', 'g'), '')
      await store.dispatch('updatePrintState', {
        checkData: {
          equeryDate: moment(eqDate).format('L'),
          equeryDateAndroid: moment(eqDate).format('YYYY-MM-DD'),
          equeryTime: moment(eqDate).format('LT'),
          equeryDateStart: moment(eqDate).format('L'),
          equeryTimeStart: moment(eqDate).format('LT'),
          equeryDateEnd: moment(eqDateEnd).format('L'),
          equeryTimeEnd: moment(eqDateEnd).format('LT'),
          docstate: response.row[0]._ServiceName,
          equeryNum: response.row[0]._equery_num
        },
        originalPlan: response.row[0]
      })
      websocket.getEquery()
      websocket.getServlistInfo(store.getters.selectedAdditionalServices.clientid, queueCallbacks.addedEQueryPlanResponse2)
      websocket.getServlistTime(store.getters.selectedAdditionalServices.clientid, queueCallbacks.addedEQueryPlanResponse3)
    }
  },

  addedEQueryPlanResponse2: async function (response) {
    if (utils.isdef(response.row)) {
      if (utils.isdef(response.row[0])) {
        await store.dispatch('updateAdditionalServices', response.row)
      } else {
        await store.dispatch('updateAdditionalServices', [response.row])
      }
      for (const k in response.row) {
        const row = response.row[k]
        await store.dispatch('updateSelectedAdditionalServicesField', {
          key: row._servId + '_time',
          val: row._servTime
        })
        await store.dispatch('updateSelectedAdditionalServicesField', {
          key: row._servId + '_time_want',
          val: row._time_start
        })
      }
    }
  },

  addedEQueryPlanResponse3: async function (response) {
    if (utils.isdef(response.service)) {
      if (utils.isdef(response.service[0])) {
        await store.dispatch('updateAdditionalServicesWantTime', response.service)
        for (const k in response.service) {
          const service = response.service[k]
          if (utils.isdef(service.row)) {
            let rows = [service.row]
            if (utils.isdef(service.row[0])) {
              rows = service.row
            }
            for (const i in rows) {
              const row = rows[i]
              row._time = row._time.replace(new RegExp('Z', 'g'), '').replace(/(\d+):(\d+):(\d+) (\d+).(\d+).(\d+)/, '$4.$5.$6 $1:$2:$3')
              const datetime = row._time.replace(new RegExp('Z', 'g'), '').replace(/(\d+).(\d+).(\d+) (\d+):(\d+):(\d+)/, '$3-$2-$1 $4:$5:$6')
              const time = moment(datetime).format('LT')
              row.time = time
            }
          } else {
            const data = store.getters.additionalServicesWantTime
            data.service.row = []
            await store.dispatch('updateAdditionalServicesWantTime', data)
          }
        }
      } else {
        await store.dispatch('updateAdditionalServicesWantTime', [response.service])
        const service = response.service
        if (utils.isdef(service.row)) {
          let rows = [service.row]
          if (utils.isdef(service.row[0])) {
            rows = service.row
          }
          for (const i in rows) {
            const row = rows[i]
            row._time = row._time.replace(new RegExp('Z', 'g'), '').replace(/(\d+):(\d+):(\d+) (\d+).(\d+).(\d+)/, '$4.$5.$6 $1:$2:$3')
            const datetime = row._time.replace(new RegExp('Z', 'g'), '').replace(/(\d+).(\d+).(\d+) (\d+):(\d+):(\d+)/, '$3-$2-$1 $4:$5:$6')
            const time = moment(datetime).format('LT')
            row.time = time
          }
        } else {
          const data = store.getters.additionalServicesWantTime
          data.service.row = []
          await store.dispatch('updateAdditionalServicesWantTime', data)
        }
      }
    }
  },

  refreshAdditionalServicesResponse: async function (response) {
    websocket.getServlistInfo(store.getters.selectedAdditionalServices.clientid, queueCallbacks.addedEQueryPlanResponse2)
    websocket.getServlistTime(store.getters.selectedAdditionalServices.clientid, queueCallbacks.addedEQueryPlanResponse3)
  },

  currentClientRowsResponse: async function (response) {
    if (typeof response !== 'undefined') {
      if (typeof response.row !== 'undefined' && utils.isdef(response.row[response.row.length - 1]._isLocked)) {
        await store.dispatch('updateBlock', response.row[response.row.length - 1]._isLocked)
        localStorage.setItem('block', store.getters.block)
      }
      if (typeof response.row !== 'undefined' && response.row.length > 2) {
        const currentClientRows = response.row.slice(0, -2)
        await store.dispatch('updateOperatorCurrentClient', currentClientRows[0])
        await store.dispatch('updateOperatorCurrentClientField', {
          key: '_couponCode',
          val: parseInt(dateService.toUIDFromEq(
            store.getters.config.servid,
            store.getters.operatorCurrentClient._time_start,
            store.getters.operatorCurrentClient._equery_num
          ))
        })
        await store.dispatch('clearCouponsByKey', 'que')
        await store.dispatch('pushCouponsByKey', {
          key: 'que',
          val: store.getters.operatorCurrentClient._couponCode
        })
        await store.dispatch('updateCurrentClient', true)
        switch (store.getters.operatorCurrentClient._status) {
          case '2':
            await store.dispatch('checkButtons', 'status_2')
            break
          case '4':
            await store.dispatch('checkButtons', 'status_4')
            break
        }
      } else {
        await store.dispatch('updateCurrentClient', false)
        await store.dispatch('updateOperatorCurrentClient', [])
        await store.dispatch('checkButtons', 'first')
      }
    }
    if (store.getters.onPause === true) {
      await store.dispatch('checkButtons', 'pause')
    }
    // в ответе запуск по таймауту запроса
    if (store.getters.config.wait_time.current_client > 0) {
      await store.dispatch('updateTimeout', {
        key: 'current_client',
        val: setTimeout(() => websocket.getCurrentClient(), store.getters.config.wait_time.current_client)
      })
    }
  },

  queueRowsResponse: async function (response) {
    const prevCount = store.getters.countCurrentQueue
    await store.dispatch('clearCouponsByKey', 'current')
    if (typeof response !== 'undefined' && +response['status-code'] === 0) {
      for (const k in response.row) {
        const row = response.row[k]
        if (row._time_start) {
          const time = row._time_start.replace(new RegExp('Z', 'g'), '')
          row.date = moment(time).format('YYYY-MM-DD')
          row.time = moment(time).format('LT')
        } else {
          row.date = moment().format('YYYY-MM-DD')
          row.time = ''
        }
        row.couponCode = parseInt(dateService.toUIDFromEq(store.getters.config.servid, row.date, row._equery_num))
        row.buttons = {
          forcenext: {
            id: 'forcenext',
            class: 'col',
            text: store.getters.translations.Call,
            disabled: !((+store.getters.block === 0 || +store.getters.block === 2) &&
              !utils.isdef(store.getters.operatorCurrentClient._status))
          },
          forcedelete: {
            id: 'forcedelete',
            class: 'col',
            text: store.getters.translations.Del,
            disabled: !((+store.getters.block === 0 || +store.getters.block === 2) &&
              !utils.isdef(store.getters.operatorCurrentClient._status))
          },
          forcePrint: {
            id: 'forcePrint',
            class: 'col',
            text: store.getters.translations.Print,
            disabled: !((+store.getters.block === 0 || +store.getters.block === 2) &&
              !utils.isdef(store.getters.operatorCurrentClient._status))
          }/*,
          send_message: {
            id: 'send_message',
            class: 'col',
            text: '<i class="send"></i>',
            disabled: !store.getters.chatCoupons.includes(row.couponCode),
            toggle: 'modal',
            target: '#send-message-modal'
          } */
        }
        await store.dispatch('pushCouponsByKey', {
          key: 'current',
          val: row.couponCode
        })
      }
      await store.dispatch('updateCurrentQueueRows', typeof response.row === 'undefined' ? [] : response.row)
    } else {
      await store.dispatch('updateCurrentQueueRows', [])
    }
    if (store.getters.currentQueueRows) {
      await store.dispatch('updateCountCurrentQueue', store.getters.currentQueueRows.length)
    } else {
      await store.dispatch('updateCountCurrentQueue', 0)
    }
    if (prevCount === 0 && store.getters.countCurrentQueue > 0) {
      websocket.beep({ blink_beep: true })
    }
    // в ответе запуск по таймауту запроса
    if (store.getters.config.wait_time.current_queue > 0) {
      await store.dispatch('updateTimeout', {
        key: 'current_queue',
        val: setTimeout(() => websocket.getEquery(), store.getters.config.wait_time.current_queue)
      })
    }
  },

  deferredRowsResponse: async function (response) {
    await store.dispatch('clearCouponsByKey', 'deferred')
    if (typeof response !== 'undefined' && typeof (response['status-code']) !== 'undefined' && +response['status-code'] === 0) {
      for (const k in response.row) {
        const row = response.row[k]
        if (row._time_start) {
          const time = row._time_start.replace(new RegExp('Z', 'g'), '')
          row.date = moment(time).format('YYYY-MM-DD')
          row.time = moment(time).format('LT')
        } else {
          row.date = moment().format('YYYY-MM-DD')
        }
        row.couponCode = parseInt(dateService.toUIDFromEq(store.getters.config.servid, row.date, row._number))
        row.buttons = {
          unhold: {
            id: 'unhold',
            class: 'col',
            text: store.getters.translations.Unpause,
            disabled: store.getters.currentClient
          },
          send_message: {
            id: 'send_message',
            class: 'col',
            text: '<i class="send"></i>',
            disabled: !store.getters.chatCoupons.includes(row.couponCode),
            toggle: 'modal',
            target: '#send-message-modal'
          }
        }
        await store.dispatch('pushCouponsByKey', {
          key: 'deferred',
          val: row.couponCode
        })
      }
      await store.dispatch('updateDeferredQueueRows', response.row)
    } else {
      await store.dispatch('updateDeferredQueueRows', [])
    }
    if (store.getters.deferredQueueRows) {
      await store.dispatch('updateCountDeferredQueue', store.getters.deferredQueueRows.length)
    } else {
      await store.dispatch('updateCountDeferredQueue', 0)
    }
    // в ответе запуск по таймауту запроса
    if (store.getters.config.wait_time.deferred_queue > 0) {
      await store.dispatch('updateTimeout', {
        key: 'deferred_queue',
        val: setTimeout(() => websocket.getEqueryDeferred(), store.getters.config.wait_time.deferred_queue)
      })
    }
  },

  waitingListResponse: async function (response) {
    if (+response['status-code'] === 0) {
      await store.dispatch('clearCouponsByKey', 'waiting')
      for (const k in response.row) {
        const row = response.row[k]
        if (row._time_start) {
          const time = row._time_start.replace(new RegExp('Z', 'g'), '')
          row.cdate = moment(time).format('YYYY-MM-DD')
          row.date = moment(time).format('L')
          row.time = moment(time).format('LT')
        } else {
          row.cdate = moment().format('YYYY-MM-DD')
          row.date = moment().format('L')
          row.time = ''
        }
        row.couponCode = parseInt(dateService.toUIDFromEq(store.getters.config.servid, row.cdate, row._equery_num))
        row.buttons = {
          addquery: {
            id: 'addquery',
            class: 'col',
            text: store.getters.translations.Put,
            toggle: 'modal',
            // 'target': '#addquery-modal',
            disabled: false,
            clientglobalid: row._id,
            docstateid: row._docstateid,
            next: row._next,
            fio: (row._fio2) ? row._fio2 : row._fio,
            href: websocket.getQueryLink(row._id, row._docstateid, (row._fio2) ? row._fio2 : row._fio)
          },
          send_message: {
            id: 'send_message',
            class: 'col',
            text: '<i class="send"></i>',
            disabled: !store.getters.chatCoupons.includes(row.couponCode),
            toggle: 'modal',
            target: '#send-message-modal',
            href: websocket.getQueryLink(row._id, row._docstateid, (row._fio2) ? row._fio2 : row._fio)
          }
        }
        await store.dispatch('pushCouponsByKey', {
          key: 'waiting',
          val: row.couponCode
        })
      }
      if (Array.isArray(response.row)) {
        await store.dispatch('updateWaitingQueueRows', response.row)
      } else {
        await store.dispatch('updateWaitingQueueRows', [response.row[0]])
      }
      if (store.getters.waitingQueueRows) {
        await store.dispatch('updateCountWaitingQueue', store.getters.waitingQueueRows.length)
      } else {
        await store.dispatch('updateCountWaitingQueue', 0)
      }
    }
    if (store.getters.config.waiting_tab && store.getters.config.wait_time.waiting_list > 0) {
      await store.dispatch('updateTimeout', {
        key: 'waiting_list',
        val: setTimeout(() => websocket.getWaitingList(), store.getters.config.wait_time.waiting_list)
      })
    }
  },

  getServicesResponse: async function (response) {
    if (+response['status-code'] === 0) {
      if (utils.isdef(response.row[0])) {
        await store.dispatch('updateServices', response.row)
      } else {
        await store.dispatch('updateServices', [response.row])
      }
    }
  },

  addedEQueryResponse: async function (response) {
    await store.dispatch('initialQueue')
    const eqDate = response.row[0]._equery_start.replace(new RegExp('Z', 'g'), '')
    const eqDateEnd = response.row[0]._equery_end.replace(new RegExp('Z', 'g'), '')
    await store.dispatch('updatePrintState', {
      checkData: {
        equeryDate: moment(eqDate).format('L'),
        equeryDateAndroid: moment(eqDate).format('YYYY-MM-DD'),
        equeryTime: moment(eqDate).format('LT'),
        equeryDateStart: moment(eqDate).format('L'),
        equeryTimeStart: moment(eqDate).format('LT'),
        equeryDateEnd: moment(eqDateEnd).format('L'),
        equeryTimeEnd: moment(eqDateEnd).format('LT'),
        docstate: response.row[0]._ServiceName,
        equeryNum: response.row[0]._equery_num
      },
      original: response.row[0]
    })
  },

  calcParamPlanResponse: function (response) {
    if (+response['status-code'] === 0) {
      let wantTime = false
      if (store.getters.selectedFreeTime) {
        wantTime = store.getters.selectedFreeTime
      } else if (utils.isdef(store.getters.freeTimes[0]._time_start)) {
        wantTime = store.getters.freeTimes[0]._time_start
      }
      if (wantTime) {
        websocket.addEqueryPlan(response.row[0]._clientid, response.row[0]._docstateid, wantTime, queueCallbacks.addedEQueryPlanResponse1)
      }
    }
  },
  calcParamResponse: function (response) {
    if (+response['status-code'] === 0) {
      websocket.addEquery(response.row[0]._clientid, response.row[0]._docstateid, store.getters.selectedFreeTime)
      websocket.getEquery()
    }
  },
  calcParamModalResponse: function (response) {
    if (+response['status-code'] === 0) {
      websocket.addEquery(response.row[0]._clientid, response.row[0]._docstateid,
        store.getters.registrationModalData.selectedFreeTime)
    }
  },

  calcParamNearestResponse: async function (response) {
    if (+response['status-code'] === 0) {
      const time = response.row[0]._equery_start.replace(new RegExp('Z', 'g'), '')
      const timeWant = moment(time).format('L') + ' ' + moment(time).format('LT')
      await store.dispatch('updateDataConfirm', {
        clientId: response.row[0]._clientid,
        docStateId: response.row[0]._docstateid,
        timeWant
      })
    }
  },

  getEQueryPeriodResponse: async function (response) {
    if (+response['status-code'] === 0) {
      let data = []
      if (utils.isdef(response.row)) {
        if (utils.isdef(response.row[0])) {
          data = response.row
        } else {
          data = [response.row]
        }
      }
      await store.dispatch('updateFreeTimes', data)
    }
  },
  getEQueryPeriodAllResponse: async function (response) {
    console.log('getEQueryPeriodAllResponse', response)
    if (+response['status-code'] === 0) {
      console.log(response)
      /* let data = []
      if (utils.isdef(response.row)) {
        if (utils.isdef(response.row[0])) {
          data = response.row
        } else {
          data = [response.row]
        }
      }
      await store.dispatch('updateFreeTimes', data) */
    }
  },

  getEQueryPeriodModalResponse: async function (response) {
    if (+response['status-code'] === 0) {
      let data = []
      if (utils.isdef(response.row)) {
        if (utils.isdef(response.row[0])) {
          data = response.row
        } else {
          data = [response.row]
        }
      }
      await store.dispatch('updateRegistrationModalDataByField', {
        key: 'freeTimes', val: data
      })
    }
  },

  getServiceParamsModalResponse: async function (response) {
    if (+response['status-code'] === 0) {
      const elementData = response.row[0]._purpose.slice(1, -1).split(';')
      const name = elementData[0]
      let type = elementData[1]
      const label = elementData[2]
      // let value = false
      await store.dispatch('updateRegistrationModalDataByField', {
        key: 'operStateId', val: response.row[0]._operstateid
      })
      let params = []
      if (name) {
        switch (type) {
          case 's':
          default:
            type = 'text'
           // value = ''
        }
        params = [{
          name: name,
          type: type,
          label: label,
          disabled: true,
          value: store.getters.registrationModalData.fio,
          origin: response.row[0]
        }]
      }
      await store.dispatch('updateRegistrationModalDataByField', {
        key: 'params', val: params
      })
    }
  }
}
