export default {
  state: {
    readyState: 0
  },
  mutations: {
    SET_READY_STATE (state, payload) {
      state.readyState = payload
    }
  },
  actions: {
    updateReadyState ({ commit }, value) {
      commit('SET_READY_STATE', value)
    }
  },
  getters: {
    readyState: state => state.readyState
  }
}
