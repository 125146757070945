import Vue from 'vue'

export default {
  state: {
    selectedFreeTime: '',
    selectedService: 0,
    fio: '',
    params: [],
    freeTimes: [],
    queueDate: '',
    countCurrentQueue: 1, // кол-во тек. список
    countDeferredQueue: 0, // кол-во отложенных
    countWaitingQueue: 0, // кол-во ожидающих
    countMessages: 0, // кол-во сообщений
    countOnline: 0, // кол-во онлайн операторов
    currentQueueRows: [], // текущий список
    deferredQueueRows: [], // список отложенных
    waitingQueueRows: [], // список ожидающих
    messageRows: [], // список сообщений
    services: [],
    currentClient: false, // наличие клиента у оператора
    fullTime: '',
    coupons: {
      current: [],
      deferred: [],
      waiting: [],
      que: []
    },
    clientId: 0,
    additionalServices: [],
    selectedAdditionalServices: {},
    additionalServicesTime: [],
    additionalServicesWantTime: [],
    operStateId: false,
    newInfoTabs: {
      tabCurrent: false,
      tabDeferred: false,
      tabWaiting: false,
      tabMessages: false,
      tabRegistration: false
    },
    grafDate: false
  },
  mutations: {
    INITIAL_STATE (state) {
      state.selectedFreeTime = ''
      state.selectedService = 0
      state.fio = ''
      state.params = []
      state.freeTimes = []
    },
    SET_SELECTED_SERVICE (state, payload) {
      state.selectedService = payload
    },
    SET_QUEUE_DATE (state, payload) {
      state.queueDate = payload
    },
    SET_SELECTED_FREE_TIME (state, payload) {
      state.selectedFreeTime = payload
    },
    SET_FULL_TIME (state, payload) {
      state.fullTime = payload
    },
    SET_CURRENT_CLIENT (state, payload) {
      state.currentClient = payload
    },
    SET_MESSAGE_ROWS (state, payload) {
      state.messageRows = payload
    },
    CLEAR_COUPONS_BY_KEY (state, payload) {
      state.coupons[payload] = []
    },
    ATTACHE_COUPONS_BY_KEY (state, payload) {
      state.coupons[payload.key].push(payload.val)
    },
    SET_CURRENT_QUEUE_ROWS (state, payload) {
      state.currentQueueRows = typeof payload !== 'undefined' ? payload : []
    },
    SET_COUNT_CURRENT_QUEUE (state, payload) {
      state.countCurrentQueue = payload
    },
    SET_DEFERRED_QUEUE_ROWS (state, payload) {
      state.deferredQueueRows = typeof payload !== 'undefined' ? payload : []
    },
    SET_COUNT_DEFERRED_QUEUE (state, payload) {
      state.countDeferredQueue = payload
    },
    SET_WAITING_QUEUE_ROWS (state, payload) {
      state.waitingQueueRows = typeof payload !== 'undefined' ? payload : []
    },
    SET_COUNT_WAITING_QUEUE (state, payload) {
      state.countWaitingQueue = payload
    },
    SET_COUNT_MESSAGE (state, payload) {
      state.countMessages = payload
    },
    SET_SERVICES (state, payload) {
      state.services = payload
    },
    SET_CLIENT_ID (state, payload) {
      state.clientId = payload
    },
    SET_ADDITIONAL_SERVICES (state, payload) {
      console.log(payload)
      state.additionalServices = payload
    },
    SET_ADDITIONAL_SERVICES_TIME (state, payload) {
      state.additionalServicesTime = payload
    },
    SET_SELECTED_ADDITIONAL_SERVICES_FIELD (state, payload) {
      Vue.set(state.selectedAdditionalServices, payload.key, payload.val)
    },
    SET_ADDITIONAL_SERVICES_WANT_TIME (state, payload) {
      state.additionalServicesWantTime = payload
    },
    SET_FREE_TIMES (state, payload) {
      state.freeTimes = payload
    },
    SET_OPER_STATE_ID (state, payload) {
      state.operStateId = payload
    },
    SET_NEW_INFO_TABS_BY_TAB (state, payload) {
      state.newInfoTabs[payload.tab] = payload.val
    },
    SET_PARAMS_BY_KEY (state, payload) {
      state.params[payload.key] = payload.val
    },
    SET_GRAF_DATE (state, payload) {
      state.grafDate = payload
    }
  },
  actions: {
    initialQueue ({ commit }) {
      commit('INITIAL_STATE')
    },
    updateSelectedService ({ commit }, value) {
      commit('SET_SELECTED_SERVICE', value)
    },
    updateQueueDate ({ commit }, value) {
      commit('SET_QUEUE_DATE', value)
    },
    updateSelectedFreeTime ({ commit }, value) {
      commit('SET_SELECTED_FREE_TIME', value)
    },
    updateFullTime ({ commit }, value) {
      commit('SET_FULL_TIME', value)
    },
    updateCurrentClient ({ commit }, value) {
      commit('SET_CURRENT_CLIENT', value)
    },
    updateMessageRows ({ commit }, rows) {
      commit('SET_MESSAGE_ROWS', rows)
    },
    clearCouponsByKey ({ commit }, key) {
      commit('CLEAR_COUPONS_BY_KEY', key)
    },
    pushCouponsByKey ({ commit }, data) {
      commit('ATTACHE_COUPONS_BY_KEY', data)
    },
    updateCurrentQueueRows ({ commit }, rows) {
      commit('SET_CURRENT_QUEUE_ROWS', rows)
    },
    updateCountCurrentQueue ({ commit }, val) {
      commit('SET_COUNT_CURRENT_QUEUE', val)
    },
    updateDeferredQueueRows ({ commit }, rows) {
      commit('SET_DEFERRED_QUEUE_ROWS', rows)
    },
    updateCountDeferredQueue ({ commit }, val) {
      commit('SET_COUNT_DEFERRED_QUEUE', val)
    },
    updateWaitingQueueRows ({ commit }, rows) {
      commit('SET_WAITING_QUEUE_ROWS', rows)
    },
    updateCountWaitingQueue ({ commit }, val) {
      commit('SET_COUNT_WAITING_QUEUE', val)
    },
    updateCountMessage ({ commit }, val) {
      commit('SET_COUNT_MESSAGE', val)
    },
    updateServices ({ commit }, val) {
      commit('SET_SERVICES', val)
    },
    updateClientId ({ commit }, val) {
      commit('SET_CLIENT_ID', val)
    },
    updateAdditionalServices ({ commit }, val) {
      commit('SET_ADDITIONAL_SERVICES', val)
    },
    updateSelectedAdditionalServicesField ({ commit }, val) {
      commit('SET_SELECTED_ADDITIONAL_SERVICES_FIELD', val)
    },
    updateAdditionalServicesWantTime ({ commit }, val) {
      commit('SET_ADDITIONAL_SERVICES_WANT_TIME', val)
    },
    updateFreeTimes ({ commit }, val) {
      commit('SET_FREE_TIMES', val)
    },
    updateOperStateId ({ commit }, val) {
      commit('SET_OPER_STATE_ID', val)
    },
    updateNewInfoTabsByTab ({ commit }, val) {
      commit('SET_NEW_INFO_TABS_BY_TAB', val)
    },
    updateParamsByKey ({ commit }, val) {
      commit('SET_PARAMS_BY_KEY', val)
    },
    updateGrafDate ({ commit }, val) {
      commit('SET_GRAF_DATE', val)
    }
  },
  getters: {
    selectedFreeTime: state => state.selectedFreeTime,
    selectedService: state => state.selectedService,
    fio: state => state.fio,
    params: state => state.params,
    freeTimes: state => state.freeTimes,
    queueDate: state => state.queueDate,
    countCurrentQueue: state => state.countCurrentQueue,
    countDeferredQueue: state => state.countDeferredQueue,
    countWaitingQueue: state => state.countWaitingQueue,
    countMessages: state => state.countMessages,
    countOnline: state => state.countOnline,
    currentQueueRows: state => state.currentQueueRows,
    deferredQueueRows: state => state.deferredQueueRows,
    waitingQueueRows: state => state.waitingQueueRows,
    messageRows: state => state.messageRows,
    services: state => state.services,
    currentClient: state => state.currentClient,
    fullTime: state => state.fullTime,
    coupons: state => state.coupons,
    clientId: state => state.clientId,
    additionalServices: state => state.additionalServices,
    selectedAdditionalServices: state => state.selectedAdditionalServices,
    additionalServicesTime: state => state.additionalServicesTime,
    additionalServicesWantTime: state => state.additionalServicesWantTime,
    operStateId: state => state.operStateId,
    newInfoTabs: state => state.newInfoTabs,
    grafDate: state => state.grafDate
  }
}
