<template>
  <div id="move-modal" class="modal fade" role="dialog">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal">&times;</button>
        </div>
        <div class="modal-body">
          <div class="form-group">
            <label for="selected_operator">{{ translations.Operator }}</label>
            <select v-model="selectedOperator" id="selected_operator" class="form-control">
              <option v-for="(operator,key) in operators" :key="key" :value="operator._operid">{{operator._oper_fio}}</option>
            </select></div>
          <div class="form-group">
            <label for="defer_minutes">{{ translations.Set_aside_min }}</label>
            <div class="input-group">
              <div class="input-group-prepend">
                <button type="button" @click="(defer_minutes-1)<1 ? 1 : defer_minutes-=1" class="btn">-</button>
              </div>
              <input class="form-control" type="number" max="100" min="1" id="defer_minutes" v-model="defer_minutes">
              <div class="input-group-append">
                <button class="btn" type="button" @click="defer_minutes+=1">+</button>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" data-dismiss="modal" @click="move">{{ translations.Move }}</button>
          <button type="button" class="btn btn-default" data-dismiss="modal">{{ translations.Close }}</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import websocket from '../../shared/websocket'
export default {
  name: 'move-modal',
  data () {
    return {
      defer_minutes: 0
    }
  },
  methods: {
    move: function () {
      websocket.moveClient(this.selectedOperator, this.clientId, this.defer_minutes)
    }
  },
  computed: {
    translations () {
      return this.$store.getters.translations
    },
    operators () {
      return this.$store.getters.moveDataModal.operators
    },
    clientId () {
      return this.$store.getters.moveDataModal.clientId
    },
    selectedOperator: {
      get () {
        return this.$store.getters.moveDataModal.selectedOperator
      },
      set (value) {
        this.$store.dispatch('updateMovedModalDataByField', { key: 'selectedOperator', val: value })
      }
    }
  }
}
</script>
