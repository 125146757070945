<template>
  <form method='post' id="setConfig" @submit="loginConfig" action='myaction' autocomplete="on">
    <label for="frmConfig">Config</label>
    <input v-model="nameConfig" id="frmConfig" name="config" type="text" autocomplete="on" class="form-control" >
    <br />
    <button type="submit" class="button" >Enter</button>
  </form>
</template>
<script>
import websocket from '../../shared/websocket'
export default {
  name: 'set-config',
  data () {
    return {
      nameConfig: ''
    }
  },
  methods: {
    loginConfig (e) {
      e.preventDefault()
      localStorage.removeItem('config_logged_out')
      localStorage.setItem('config', this.nameConfig.hexDecode())
      this.$store.dispatch('updateConfigLoggedOut', false)
      this.$store.dispatch('updateConfig').then(() => {
        this.$store.dispatch('updateOperators', [])
        const config = this.$store.getters.config
        if (typeof config.server !== 'undefined') {
          websocket.websocketStart(config.server)
        }
      })
      this.nameConfig = ''
      return false
    }
  }
}
</script>
