export default {
  state: {
    registrationModalData: {
      clientGlobalId: false,
      docStateId: false,
      next: false,
      fio: false,
      services: [],
      params: [],
      freeTimes: [],
      selectedFreeTime: '',
      selectedService: 0,
      operStateId: false,
      queueDate: false
    }
  },
  mutations: {
    SET_REGISTRATION_MODAL_DATA_BY_FIELD (state, payload) {
      state.registrationModalData[payload.key] = payload.val
    }
  },
  actions: {
    updateRegistrationModalDataByField ({ commit }, payload) {
      commit('SET_REGISTRATION_MODAL_DATA_BY_FIELD', payload)
    }
  },
  getters: {
    registrationModalData: state => state.registrationModalData
  }
}
