import store from '../../store/store'
import utils from '../libs/utils'

export default {

  commitEQueryResponse: async function (response) {
    if (+response['status-code'] === 0 && response.number && +response.number > 0) {
      await store.dispatch('updateEQueryNum', +response.number)
      const printData = store.getters.printData
      printData.original._equery_num = +response.number
      await store.dispatch('updatePrintState', printData)
      await store.dispatch('updateShowPrintInfo', true)
    }
  },
  getDynamicsCountResponse: async function (response) {
    if (utils.isdef(response.dynamics_count)) {
      if (response.dynamics_count.row._count > 0) {
        await store.dispatch('updateNearest', true)
      } else {
        await store.dispatch('updateNearest', false)
      }
      await store.dispatch('updateNearestCount', response.dynamics_count.row._count)
    }
  },

  getEQueryPeriodResponse: async function (response) {
    if (+response['status-code'] === 0) {
      if (utils.isdef(response.row)) {
        if (utils.isdef(response.row[0])) {
          await store.dispatch('updateFreeTimes', response.row)
        } else {
          await store.dispatch('updateFreeTimes', [response.row])
        }
      } else {
        await store.dispatch('updateFreeTimes', [])
      }
    }
  },

  getServiceParamsResponse: async function (response) {
    if (+response['status-code'] === 0) {
      const elementData = response.row[0]._purpose.slice(1, -1).split(';')
      const name = elementData[0]
      let type = elementData[1]
      const label = elementData[2]
      // let value = false
      await store.dispatch('updateOperStateId', response.row[0]._operstateid)

      if (name) {
        switch (type) {
          case 's':
          default:
            type = 'text'
            // value = ''
        }
        await store.dispatch('updateRegistrationParams', [{
          name: name,
          type: type,
          label: label,
          disabled: true,
          value: store.getters.fio,
          origin: response.row[0]
        }])
      } else {
        await store.dispatch('updateRegistrationParams', [])
      }
    }
  }
}
