export default {
  state: {
    buttons: {
      next: {
        id: 'next',
        class: 'col-6',
        text: '',
        disabled: false
      },
      start: {
        id: 'start',
        class: 'col-6',
        text: '',
        disabled: true
      },
      complete: {
        id: 'complete',
        class: 'col-6',
        text: '',
        disabled: true
      },
      move: {
        id: 'move',
        class: 'col-6',
        text: '',
        toggle: 'modal',
        target: '#move-modal',
        disabled: true
      },
      delete: {
        id: 'delete',
        class: 'col-6',
        text: '',
        disabled: true
      },
      hold: {
        id: 'hold',
        class: 'col-6',
        text: '',
        disabled: true
      },
      pause: {
        id: 'pause',
        class: 'col-6',
        text: '',
        disabled: false
      },
      send_message: {
        id: 'send_message',
        class: 'col-6',
        text: '<i class="send"></i>',
        disabled: true,
        couponCode: '',
        toggle: 'modal',
        target: '#send-message-modal'
      }
    }
  },
  mutations: {
    SET_BUTTON_FIELDS (state, payload) {
      for (const key in payload.keys) {
        state.buttons[payload.button][key] = payload.keys[key]
      }
    },
    SET_BUTTONS_FIELDS (state, payload) {
      for (const button in payload) {
        for (const field in payload[button]) {
          state.buttons[button][field] = payload[button][field]
        }
      }
    },
    UPDATE_TEXT_BUTTONS (state, payload) {
      state.buttons.next.text = payload.Next
      state.buttons.start.text = payload.Start
      state.buttons.complete.text = payload.Finish
      state.buttons.move.text = payload.Move
      state.buttons.delete.text = payload.Remove
      state.buttons.hold.text = payload.Defer
      state.buttons.pause.text = payload.Pause
    }
  },
  actions: {
    updateButtonFields ({ commit }, data) {
      commit('SET_BUTTON_FIELDS', data)
    },
    checkButtons ({ commit, rootGetters }, type) {
      let button = {}
      switch (type) {
        case 'status_2':
          if (+rootGetters.block === 2) {
            button = { pause: { id: 'pause', disabled: true, text: rootGetters.translations.Pause } }
          } else if (+rootGetters.block === 1) {
            button = { pause: { id: 'unpause', disabled: false, text: rootGetters.translations.Back_work } }
          } else {
            button = { pause: { id: 'pause', disabled: false, text: rootGetters.translations.Pause } }
          }
          commit('SET_BUTTONS_FIELDS', {
            next: { disabled: true, id: 'next', text: rootGetters.translations.Next },
            start: { disabled: true },
            complete: { disabled: false },
            move: { disabled: true },
            delete: { disabled: true },
            hold: { disabled: false },
            send_message: {
              couponCode: rootGetters.operatorCurrentClient._couponCode,
              disabled: !rootGetters.chatCoupons.includes(rootGetters.operatorCurrentClient._couponCode)
            },
            ...button
          })
          break
        case 'status_4':
          if (+rootGetters.block === 2) {
            button = { pause: { id: 'pause', disabled: true, text: rootGetters.translations.Pause } }
          } else if (+rootGetters.block === 1) {
            button = { pause: { id: 'unpause', disabled: false, text: rootGetters.translations.Back_work } }
          } else {
            button = { pause: { id: 'pause', disabled: false, text: rootGetters.translations.Pause } }
          }
          commit('SET_BUTTONS_FIELDS', {
            next: { disabled: false, id: 'more', text: rootGetters.translations.Repeated_call },
            start: { disabled: false },
            complete: { disabled: true },
            move: { disabled: false },
            delete: { disabled: false },
            hold: { disabled: true },
            send_message: {
              couponCode: rootGetters.operatorCurrentClient._couponCode,
              disabled: !rootGetters.chatCoupons.includes(rootGetters.operatorCurrentClient._couponCode)
            },
            ...button
          })
          break
        case 'first':
          if (+rootGetters.block === 2) {
            button = { pause: { id: 'pause', disabled: true, text: rootGetters.translations.Pause } }
          } else if (+rootGetters.block === 1) {
            button = { pause: { id: 'unpause', disabled: false, text: rootGetters.translations.Back_work } }
          } else {
            button = { pause: { id: 'pause', disabled: false, text: rootGetters.translations.Pause } }
          }
          commit('SET_BUTTONS_FIELDS', {
            next: { disabled: false, id: 'next', text: rootGetters.translations.Next },
            start: { disabled: true },
            complete: { disabled: true },
            move: { disabled: true },
            delete: { disabled: true },
            hold: { disabled: true },
            send_message: { disabled: true },
            ...button
          })
          break
        case 'pause':
          commit('SET_BUTTONS_FIELDS', {
            next: { disabled: true },
            start: { disabled: true },
            complete: { disabled: true },
            move: { disabled: true },
            delete: { disabled: true },
            hold: { disabled: true },
            send_message: { disabled: true },
            pause: { id: 'unpause', disabled: false, text: rootGetters.translations.Back_work }
          })
          break
      }
    }
  },
  getters: {
    buttons: state => state.buttons
  }
}
