<template>
  <div class="swiper-slide pult-panel">
    <section id="second-app">
      <div class="left-landscape">
        <header-date-time/>
        <current-operator/>
        <ticket/>
      </div>
      <div class="right-landscape">
        <setting/>
        <operator-actions/>
      </div>
    </section>
  </div>
</template>
<script>
import HeaderDateTime from './HeaderDateTime'
import CurrentOperator from './CurrentOperator'
import Ticket from './Ticket'
import OperatorActions from './OperatorActions'
import Setting from './Setting'
export default {
  name: 'console-panel',
  components: { HeaderDateTime, CurrentOperator, Ticket, OperatorActions, Setting }
}
</script>
